import React, { useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import "./export-menu.css"; // Import your styles here

const ExportDropdown = ({ onExportSelect }) => {
  const [selectedExport, setSelectedExport] = useState('Export GSP Overview');

  const handleMenuClick = (e) => {
    const exportOptions = {
      Xtime: 'Export to Xtime',
      Infomedia: 'Export to Infomedia',
    };
    
    const selectedLabel = exportOptions[e.key] || 'Export GSP Overview';

    if (onExportSelect) {
      onExportSelect(e.key);
      setSelectedExport(selectedLabel);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick} className="custom-menu">
      <Menu.Item key="Xtime">Export to Xtime</Menu.Item>
      <Menu.Item key="Infomedia">Export to Infomedia</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} className="export-dropdown-menu">
      <Button className="add-button-style">
        {selectedExport} <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ExportDropdown;
