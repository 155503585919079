import axios from "axios";
import { auth } from "./authService";

const BASE_URL = process.env.REACT_APP_API_URL;

const apiService = {
	get: async (url, headers = {}) => {
		try {
			const token = auth.getToken();
			const response = await axios.get(`${BASE_URL}${url}`, {
				headers: {
					...headers,
					Authorization: `${token}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error(error.response.data.message);
		}
	},

	post: async (url, data, headers = {}) => {
		try {
			const token = auth.getToken();
			const response = await axios.post(`${BASE_URL}${url}`, data, {
				headers: {
					...headers,
					Authorization: `${token}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error(error.response.data.message);
		}
	},

	put: async (url, data, headers = {}) => {
		try {
			const token = auth.getToken();
			const response = await axios.put(`${BASE_URL}${url}`, data, {
				headers: {
					...headers,
					Authorization: `${token}`,
				},
			});
			return response.data;
		} catch (error) {
			throw new Error(error.response.data.message);
		}
	},

	delete: async (url, data, headers = {}) => {
		try {
			const token = auth.getToken();
			const response = await axios.delete(`${BASE_URL}${url}`, {
				headers: {
					...headers,
					Authorization: `${token}`,
				},
				data,
			});
			return response.data;
		} catch (error) {
			throw new Error(error.response.data.message);
		}
	},
};

export default apiService;
