import { Row, Col, Select, Modal } from "antd";
import "./sundry-header.css";
import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import {
  getMaxCharsRule,
  onHandleNumericValidation,
  requiredRule,
  requiredSelectRule,
} from "../../../CommonComponents/form/Validation";
import apiService from "../../../Services/commonServices";
import { listRSAdealerPayments } from "../../../Services/Endpoints";
import AddButton from "../../../CommonComponents/Add-Button/AddButton";
import { commonAlphanumericSorter } from "../../../CommonComponents/SortingLogic";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const SundryHeader = ({ onAddSundryItem, isModelSelected, resetValue, editValue }) => {
  const [form] = Form.useForm();
  const [allSundries, setAllSundries] = useState([]);
  const [sundryItem, setSundryItem] = useState({});
  const [enableReset, setEnableReset] = useState(false)

  useEffect(() => {
    fetchSundries();
  }, []);

  useEffect(() => {
    handleReset()
  }, [resetValue])

  const fetchSundries = async () => {
    try {
      const responseData = await apiService.get(listRSAdealerPayments);
      const sortedData = commonAlphanumericSorter(responseData?.data, "code");
      setAllSundries(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleSelectSundry = (value) => {
    setEnableReset(true)
    const sundryItem = allSundries.find((eachObj) => eachObj.code === value);
    setSundryItem({ id: sundryItem.id, description: sundryItem.description });
    form.setFieldsValue({
      value: sundryItem.value,
    });
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const formData = form.getFieldsValue();
      const sundryItemData = {
        ...sundryItem,
        code: formData.sundry,
        value: formData.value,
      };
      onAddSundryItem(sundryItemData, form);
    } catch (error) {}
  };

  
  const handleReset = () => {
    form.resetFields();
  };


  const handleKeyPress = (e) => {
    setEnableReset(true)
    onHandleNumericValidation(e, form, "value");
    if (e.target.value.length >= 10 && e.key !== "Backspace") {
      e.preventDefault();
      form.setFields([
        {
          name: "value",
          errors: ["Max 10 characters"],
        },
      ]);
    }
  };

   const confirmReset = () => {
    Modal.confirm({
      title: 'Confirm Discard',
      className: "delete-popup",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to reset all fields?',
      okText: "Yes",
      cancelText: "Cancel",
      onOk() {
        form.resetFields();
        setEnableReset(false)
      },
    });
  };

  return (
    <div className="sundry-header-main-bg">
      <h1 className="add-sundry-title">Add Sundry</h1>
      <div className="sundry-header-bg">
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          disabled={!isModelSelected || editValue}
        >
          <div className="form-items-arrange">
            <div>
              <Form.Item
                name="sundry"
                label="Sundry"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[requiredSelectRule]}
              >
                <Select
                  placeholder="Select Sundry"
                  showSearch
                  onChange={handleSelectSundry}
                >
                  {allSundries.map((option) => (
                    <Option id={option.id} key={option.id} value={option.code}>
                      {option.code}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              </div>
              <div>
              <Form.Item
                name="value"
                label="Value"
                rules={[requiredRule, getMaxCharsRule("value", 10)]}
                labelCol={{ span: 24 }}
                style={{ width: "170px" }}
              >
                <Input
                  placeholder="Enter Value"
                  addonBefore="$"
                  className="value-input"
                  onBlur={() => {
                    form.validateFields(["value"]);
                  }}
                  onKeyPress={handleKeyPress}
                  autoComplete="off"
                />
              </Form.Item>
              </div>
              <div>
              <Form.Item >
                <Button disabled={!enableReset} style={{ height: "31px" }} onClick={confirmReset}>
                  Reset
                </Button>
                <AddButton
                  onClick={handleSubmit}
                  text={"Sundry"}
                  customHeight={"32px"}
                />
              </Form.Item>
              </div>
          </div>
          
        </Form>
      </div>
    </div>
  );
};

export default SundryHeader;
