import React, { useState, useEffect, useRef } from "react";
import "./model.css";
import EmptyMasterData from "../EmptyMasterData/EmptyMasterData";
import CommonTable from "../../../CommonComponents/Table/CommonTable";
import { message, Tooltip } from "antd";
import { commonSorter } from "../../../CommonComponents/SortingLogic";
import apiService from "../../../Services/commonServices";
import {
	modelDetails,
	engineType,
	RecommendedServices,
	editModel,
	sapCode,
} from "../../../Services/Endpoints";
import { Modal, Form, Input, Row, Col, Select } from "antd";
import {
	getMaxCharsRule,
	requiredRule,
	requiredSelectRule,
} from "../../../CommonComponents/form/Validation";

const Models = () => {
	const hiddenSubmitButton = useRef(null);
	const [isEmpty, setIsEmpty] = useState(false);
	const [allModel, setAllModel] = useState([]);
	const [allModelDetails, setAllModelDetails] = useState([]);
	const [showModal, setShowModal] = useState("");
	const [form] = Form.useForm();
	const { Option } = Select;
	const [currentRecord, setCurrentRecord] = useState(null);
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [allEngineType, setAllEngineType] = useState([]);
	const [allRecommendedServices, setAllRecommendedServices] = useState([]);
	const [editDetails, setEditDetails] = useState([]);
	const [uniqueSapCodes, setUniqueSapCodes] = useState([]);
	const [editModelName, setEditModelName] = useState("");
	const [editFactoryCode, setEditFactoryCode] = useState("");
	const [editVersion, setEditVersion] = useState("");
	const [editModelType, setEditModelType] = useState("");
	const [editFuelType, setEditFuelType] = useState("");
	const [editVariant, setEditVariant] = useState("");
	const [editGWMSCode, setEditGWMSCode] = useState("");
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [modelName, setModelName] = useState("");

	const fetchData = async () => {
		try {
			setIsLoading(true);
			const [
				modelResponse,
				engineTypeResponse,
				recommendedServicesResponse,
				sapcodeResponse,
			] = await Promise.all([
				apiService.get(modelDetails),
				apiService.get(engineType),
				apiService.get(RecommendedServices),
				apiService.get(sapCode),
			]);

			const modelData = modelResponse?.data;
			const engineTypeData = engineTypeResponse?.data;
			const recommendedServiceData = recommendedServicesResponse?.data;
			const sapCodeData = sapcodeResponse?.data;

			setIsEmpty(!modelData);
			setAllModel(modelData);
			setAllModelDetails(modelData);
			setAllEngineType(engineTypeData);
			setAllRecommendedServices(recommendedServiceData);
			setUniqueSapCodes(sapCodeData);
		} catch (error) {
			console.error("Error fetching data:", error.message);
		} finally {
			setIsLoading(false);
		}
	};

	const onValuesChange = (changedValues, values) => {
		if (
			isEdit &&
			editFactoryCode?.toLowerCase() === values.factoryCode?.toLowerCase()
		) {
			return;
		}

		const checkDuplicate = allModel.some((item) => {
			const engineTypeName = allEngineType.find(
				(type) => type.id === values.engineTypeId
			)?.name;

			return (
				item.modelName?.toLowerCase() === values.modelName?.toLowerCase() &&
				item.factoryCode?.toLowerCase() === values.factoryCode?.toLowerCase() &&
				item.version?.toLowerCase() === values.version?.toLowerCase() &&
				item.modelType?.name?.toLowerCase() ===
					values.modelType?.toLowerCase() &&
				item.engineType?.name?.toLowerCase() ===
					engineTypeName?.toLowerCase() &&
				item.modelVariant?.name?.toLowerCase() ===
					values.modelVariant?.toLowerCase()
			);
		});

		if (checkDuplicate) {
			setIsDuplicate(true);
		} else {
			setIsDuplicate(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const validateGwmsServiceCode = async (rule, value) => {
		if (value) {
			const trimmedValue = value.trim();
			if (isEdit && editGWMSCode === trimmedValue) {
				return;
			}
			const exists = allModel.some(
				(model) =>
					model?.gwmsServiceCode === trimmedValue &&
					model.id !== form.getFieldValue("id")
			);

			if (exists) {
				throw new Error("Duplicate GWMS code. Please enter a unique code.");
			}
		}
	};

	const onHandleSearch = (e) => {
		const { value } = e.target;
		const filteredItems = allModelDetails.filter((item) => {
			const searchValue = value?.toLowerCase();
			const fields = [
				item.modelName,
				item.factoryCode,
				item.version,
				item.modelType?.name,
				item.engineNumber,
				item.engineType?.name,
				item.recommendedServiceDetails?.name,
				item.modelVariant?.name,
				item?.gwmsServiceCode,
				item.sapCode,
				(item.trim ?? "").trim(),
			];

			return fields.some((field) => field?.toLowerCase().includes(searchValue));
		});
		setAllModel(filteredItems);
	};
	const columns = [
		{
			title: (
				<Tooltip title='Factory Model Code'>
					<span>Factory Model Code</span>
				</Tooltip>
			),
			dataIndex: "Factory Model Code",
			render: (_, record) => (
				<Tooltip title={record?.factoryCode}>
					<span className='record-text ellipsis' style={{}}>
						{record?.factoryCode}
					</span>
				</Tooltip>
			),
			className: "ellipsis",
		},
		{
			title: "Model Name",
			dataIndex: "modelName",
			render: (_, record) => (
				<span className='record-text' style={{}}>
					{record?.modelName}
				</span>
			),
			width: 130,
			sorter: commonSorter("modelName"),
		},
		{
			title: "Version",
			dataIndex: "Version",
			render: (_, record) => (
				<span className='record-text' style={{}}>
					{record?.version}
				</span>
			),
			width: 85,
			sorter: commonSorter("version"),
		},
		{
			title: "Model Type",
			dataIndex: "modelType.name",
			render: (_, record) => (
				<span className='record-text' style={{}}>
					{record?.modelType?.name}
				</span>
			),
			width: 120,
			sorter: (a, b) => a.modelType?.name.localeCompare(b.modelType?.name),
		},
		{
			title: "Engine Type",
			dataIndex: "engineNumber",
			render: (_, record) => (
				<span className='record-text' style={{}}>
					{record?.engineNumber}
				</span>
			),
			width: 100,
		},
		{
			title: "Fuel Type",
			dataIndex: "engineType.name",
			render: (_, record) => (
				<span className='record-text' style={{}}>
					{record?.engineType?.name}
				</span>
			),
			width: 100,
			sorter: (a, b) => a.engineType?.name.localeCompare(b.engineType?.name),
		},

		{
			title: (
				<Tooltip title='Recommended Service'>
					<span className='ellipsis'>Recommended Service</span>
				</Tooltip>
			),
			dataIndex: "recommendedServiceId",
			className: "ellipsis",
			render: (_, record) => (
				<Tooltip title={record?.recommendedServiceDetails?.name}>
					<span className='record-text ellipsis' style={{}}>
						{record?.recommendedServiceDetails?.name}
					</span>
				</Tooltip>
			),
			width: 250,

			sorter: (a, b) =>
				a.recommendedServiceDetails?.name.localeCompare(
					b.recommendedServiceDetails.name
				),
		},

		{
			title: "Variant",
			dataIndex: "modelVariant",
			render: (_, record) => (
				<span className='record-text' style={{}}>
					{record?.modelVariant?.name}
				</span>
			),
			width: 80,
		},
		{
			title: "SAP Code",
			dataIndex: "sapCode",
			render: (_, record) => (
				<span className='record-text' style={{}}>
					{record?.sapCode}
				</span>
			),
			width: 90,
		},
		{
			title: "GWMS Code",
			dataIndex: "gwmsServiceCode",
			render: (_, record) => (
				<span className='record-text' style={{}}>
					{record?.gwmsServiceCode}
				</span>
			),
			width: 100,
		},
		{
			title: "Trim",
			dataIndex: "trim",
			render: (_, record) => (
				<span className='record-text' style={{}}>
					{record?.trim}
				</span>
			),
			width: 80,
		},
	];

	const ShowModal = (data) => {
		setShowModal(data);
		setIsFormChanged(true);
		form.resetFields();
	};

	const handleFinish = () => {
		form
			.validateFields()
			.then(async (values) => {
				try {
					if (isEdit) {
						const responseData = await apiService.put(
							editModel.replace("{id}", editDetails.id),
							{
								...currentRecord,
								...values,
							}
						);
						const data = responseData?.data;
						message.success(responseData?.message);
						await fetchData();
					} else {
						const responseData = await apiService.post(modelDetails, {
							...currentRecord,
							...values,
						});
						const data = responseData?.data;
						message.success(responseData?.message);
						setIsLoading(true);
						await fetchData();
					}
					form.resetFields();
				} catch (error) {
					message.error(error.message);
					console.error("Error fetching data:", error.message);
				}
				setShowModal(false);
				form.resetFields();
				setIsFormChanged(false);
				setIsDuplicate(false);
			})
			.catch((errorInfo) => {
				console.log("Validation failed:", errorInfo);
			});
	};

	const handleCancel = () => {
		setIsEdit(false);
		setShowModal(false);
		setCurrentRecord(null);
		form.resetFields();
		setIsFormChanged(false);
		setIsDuplicate(false);
	};

	const handleSubmit = () => {
		handleFinish();
	};

	const onHandleEdit = async (record) => {
		setIsEdit(true);
		const editModelResponse = await apiService.get(
			editModel.replace("{id}", record.id)
		);
		setEditDetails(editModelResponse?.data);
		const fuelType = allEngineType.filter(
			(item) => item.name === editModelResponse?.data?.engineType?.name
		);
		setEditModelName(editModelResponse?.data?.modelName);
		setEditFactoryCode(editModelResponse?.data?.factoryCode);
		setEditVersion(editModelResponse?.data?.version);
		setEditModelType(editModelResponse?.data?.modelType?.name);
		setEditGWMSCode(editModelResponse?.data?.gwmsServiceCode);
		setEditFuelType(fuelType[0]?.id);
		setEditVariant(editModelResponse?.data?.modelVariant?.name);
		form.setFieldsValue({
			factoryCode: editModelResponse?.data?.factoryCode,
			modelName: editModelResponse?.data?.modelName,
			version: editModelResponse?.data?.version,
			modelType: editModelResponse?.data?.modelType?.name,
			engineNumber: editModelResponse?.data?.engineNumber,
			engineTypeId: fuelType[0]?.id,
			recommendedServiceId:
				editModelResponse?.data?.recommendedServiceDetails?.id,
			modelVariant: editModelResponse?.data?.modelVariant?.name,
			sapCode: editModelResponse?.data?.sapCode,
			gwmsServiceCode: editModelResponse?.data?.gwmsServiceCode,
			trim: editModelResponse?.data?.trim,
			id: editModelResponse?.data?.id,
		});
		setShowModal(true);
	};

	const onHandleDelete = async (record) => {
		try {
			const url = `${modelDetails}${record.id}`;
			const response = await apiService.delete(url);
			message.success(response.message);
			fetchData();
		} catch (error) {
			message.warning(error.message);
		}
	};

	const onHandleFilter = (e) => {
		if (
			e.fuel_type?.toLowerCase() === "all" &&
			e.sap_code?.toLowerCase() === "all"
		) {
			setAllModel(allModelDetails);
		} else if (e.fuel_type?.toLowerCase() === "all") {
			const filteredItems = allModelDetails.filter(
				(item) => item?.sapCode?.toLowerCase() === e.sap_code?.toLowerCase()
			);
			setAllModel(filteredItems);
		} else if (e.sap_code?.toLowerCase() === "all") {
			const filteredItems = allModelDetails.filter(
				(item) =>
					item?.engineType?.name?.toLowerCase() === e.fuel_type?.toLowerCase()
			);
			setAllModel(filteredItems);
		} else {
			const filteredItems = allModelDetails.filter(
				(item) =>
					item?.sapCode?.toLowerCase() === e.sap_code?.toLowerCase() &&
					item?.engineType?.name?.toLowerCase() === e.fuel_type?.toLowerCase()
			);
			setAllModel(filteredItems);
		}
	};

	return (
		<>
			{isEmpty ? (
				<EmptyMasterData />
			) : (
				<>
					<CommonTable
						columns={columns}
						dataSource={allModel}
						rowKey='id'
						onClickAdd={ShowModal}
						resourceName={"Model"}
						onEdit={onHandleEdit}
						onDelete={onHandleDelete}
						isLoading={isLoading}
						onChange={onHandleSearch}
						filterData={onHandleFilter}
						uniqueSapCodes={uniqueSapCodes}
						allEngineType={allEngineType}
					/>
					<Modal
						title={`${isEdit ? "Edit" : "Add"} Model`}
						open={showModal}
						onOk={handleSubmit}
						onCancel={handleCancel}
						okText={isEdit ? "Save" : "Add"}
						okButtonProps={{ disabled: !isFormChanged || isDuplicate }}
						width={800}
						centered
						className='custom-modal'>
						<Form
							form={form}
							layout='vertical'
							onFinish={handleSubmit}
							onFieldsChange={() => setIsFormChanged(form.isFieldsTouched())}
							onValuesChange={onValuesChange}>
							<Row gutter={16}>
							<Col span={10}>
									<Form.Item
										name='gwmsServiceCode'
										label='GWMS Code'
										rules={[
											requiredRule,
										]}
										>
										<Input placeholder='Enter GWMS Code' />
									</Form.Item>
								</Col>
								<Col span={7}>
									<Form.Item
										name='factoryCode'
										label='Factory Model Code'
										rules={[
											getMaxCharsRule("Factory Model Code"),
										]}>
										<Input
											autoComplete='off'
											placeholder='Enter Factory Model Code'
										/>
									</Form.Item>
								</Col>
								<Col span={7}>
									<Form.Item
										name='modelName'
										label='Model Name'
										rules={[ getMaxCharsRule("Model Name")]}>
										<Input
											autoComplete='off'
											placeholder='Enter Model Name'
											onChange={(e) => {
												setModelName(e.target.value);
											}}
										/>
									</Form.Item>
								</Col>
								
							</Row>
							<Row gutter={16}>
							<Col span={5}>
									<Form.Item
										name='version'
										label='Version'
										rules={[getMaxCharsRule("Version")]}>
										<Input autoComplete='off' placeholder='Enter Version' />
									</Form.Item>
								</Col>
								<Col span={5}>
									<Form.Item
										name='modelType'
										label='Model Type'
										rules={[ getMaxCharsRule("Model Type")]}>
										<Input autoComplete='off' placeholder='Enter Model Type' />
									</Form.Item>
								</Col>
								<Col span={7}>
									<Form.Item
										name='engineNumber'
										label='Engine Type'
										rules={[getMaxCharsRule("Engine Type")]}>
										<Input autoComplete='off' placeholder='Enter Engine Type' />
									</Form.Item>
								</Col>

								<Col span={7}>
									<Form.Item
										name='engineTypeId'
										label='Fuel Type'
										>
										<Select placeholder='Select Fuel Type'>
											{allEngineType.map((option) => (
												<Option key={option.id} value={option.id}>
													{option.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>

								<Col span={10}>
									<Form.Item
										name='recommendedServiceId'
										label='Recommended Service '
										>
										<Select placeholder='Select Recommended Service '>
											{allRecommendedServices.map((option) => (
												<Option key={option.id} value={option.id}>
													{option.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col span={7}>
									<Form.Item name='modelVariant' label='Variant'>
										<Input placeholder='Enter Variant' />
									</Form.Item>
								</Col>
								<Col span={7}>
									<Form.Item name='sapCode' label='SAP Code'>
										<Input placeholder='Enter SAP Code' />
									</Form.Item>
								</Col>
							</Row>
							
							<Row gutter={16}>
								<Col span={7}>
									<Form.Item name='trim' label='Trim'>
										<Input placeholder='Enter Trim' />
									</Form.Item>
								</Col>
							</Row>
							<Row>
								{isDuplicate && (
									<Form.Item>
										<span
											style={{
												color: "red",
											}}>{`Note: The data provided matches the model '${modelName}'. Please try a different input.`}</span>
									</Form.Item>
								)}
							</Row>
							<button
								ref={hiddenSubmitButton}
								type='submit'
								style={{ display: "none" }}
							/>
						</Form>
					</Modal>
				</>
			)}
		</>
	);
};

export default Models;
