export const onHandleValueInputChange = (e, formData, setFormData, formErrors, setFormErrors) => {
  let { value } = e.target;
  const reg = /^\d{0,10}(\.\d{0,2})?$/;
  if (value.trim().length === 0) {
    setFormData((prevState) => ({
      ...prevState,
      value: "", 
    }));
    setFormErrors({ ...formErrors, value: "This field is required." });
    return;
  }

  if (/^[0-9.]*$/.test(value)) {
    if (value.length <= 10) {
      if (reg.test(value) || value === "") {
        if (value.indexOf('.') !== -1) {
          if (value.split('.')[0].length === 0) {
            setFormErrors({ ...formErrors, value: "Numeric values only." });
            return;
          }
        }
        setFormData((prevState) => ({
          ...prevState,
          value: value,
        }));
        setFormErrors((prevErrors) => ({ ...prevErrors, value: "" }));
      } else {
        setFormErrors({ ...formErrors, value: "Max 2 decimals allowed." });
      }
    } else {
      setFormErrors({ ...formErrors, value: "Max 10 characters." });
    }
  } else {
    setFormErrors({ ...formErrors, value: "Numeric values only." });
  }
};

export const handleDescriptionInputChange = (e, setFormData, setFormErrors, setDisableSave, formErrors) => {
  const { value } = e.target;
  
  const truncatedValue = value.slice(0, 200);
  setDisableSave(false);
  setFormData((prevState) => ({
    ...prevState,
    description: truncatedValue,
    remainingCharlength: 200 - truncatedValue.length,
  }));

  if (value.length <= 200) {
    setFormErrors({
      ...formErrors,
      showRemainingChar: true,
      description: "",
    });
  } else {
    setFormErrors({
      ...formErrors,
      showRemainingChar: false,
      description: "Max 200 characters.",
    });
  }
};
