import React from "react";
import "./home.css";
import { Col, Row, Skeleton } from "antd";
import { useRecoilState } from "recoil";
import { sideNavCollapsedState } from "../../Recoil/recoilState";
import { homeMasterData, mainCardlabels } from "./Home";

const HomeSkelton = (props) => {
  const [collapsed] = useRecoilState(sideNavCollapsedState);

  return (
    <div style={{ margin: "12px" }}>
      <div className="home-bg-container">
        <h2 className="main-title">Master Data Configuration</h2>
        <Row gutter={[14, 14]} justify="start">
          {homeMasterData.map((eachObject) => (
            <Col
              xs={12}
              sm={8}
              md={collapsed ? 6 : 8}
              lg={6}
              xl={6}
              key={eachObject.id}
            >
            <Skeleton active />
            </Col>
          ))}
        </Row>
      </div>
      <Row gutter={[12, 12]} justify="start">
        {mainCardlabels.map((eachObject) => (
          <Col
            xs={24}
            sm={12}
            md={collapsed ? 12 : 12}
            lg={8}
            xl={6}
            xxl={6}
            key={eachObject.id}
          >
         <Skeleton active />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default HomeSkelton;
