import { FilterOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./filter.css";
import { Drawer } from "antd";
import { Select, Button } from "antd";

export const CustomFilter = ({
  filterData,
  uniqueSapCodes,
  allEngineType,
  resourceName,
  modelDetail,
}) => {
  const [open, setOpen] = useState(false);
  const { Option } = Select;
  const [sapCode, setSapCode] = useState("All");
  const [fuelType, setFuelType] = useState("All");
  const [modelName, setModelName] = useState("All");
  const [engineType, setEngineType] = useState("All");
  const [code, setCode] = useState("All");
  const [uniqueCode, setUniqueCode] = useState([]);
  const [uniqueEngineType, setUniqueEngineType] = useState([]);

  const uniqueModelNames = [
    ...new Set(modelDetail?.map((model) => model?.modelDetails?.modelName)),
  ];

  useEffect(() => {
    const uniqueFactoryCodes = [
      ...new Set(modelDetail?.map((item) => item.modelDetails.factoryCode)),
    ];
    const uniqueEngineTypes = [
      ...new Set(modelDetail?.map((item) => item.modelDetails.engineNumber)),
    ];

    setUniqueCode(uniqueFactoryCodes);
    setUniqueEngineType(uniqueEngineTypes);
  }, [modelDetail]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onchangeHandleSapCode = (e) => {
    setSapCode(e);
  };

  const onchangeHandleModelName = (e) => {
    setModelName(e);
  };

  const onchangeHandleFuelType = (e) => {
    setFuelType(e);
  };

  const onchangeHandleEngineType = (e) => {
    setEngineType(e);
  };

  const onchangeHandleCode = (e) => {
    setCode(e);
  };

  const handleFilter = () => {
    resourceName == "gsp-overview"
      ? filterData({
          code: code,
          engine_type: engineType,
          model_Name: modelName,
        })
      : filterData({
          sap_code: sapCode,
          fuel_type: fuelType,
        });
    setOpen(false);
  };

  const handleClearFilter = () => {
    setFuelType("All");
    setSapCode("All");
    setModelName("All");
    setEngineType("All");
    setCode("All");
    resourceName == "gsp-overview"
      ? filterData({ code: "All", engine_type: "All", model_Name: "All" })
      : filterData({ sap_code: "All", fuel_type: "All" });
  };

  return (
    <div className="custom-search-parent-container">
      <Button
        onClick={() => showDrawer()}
        shape="square"
        type="primary"
        className="custom-filter"
        icon={<FilterOutlined />}
      />
      {/* <FilterOutlined onClick={showDrawer} className="custom-filter" /> */}
      <Drawer
        title="Filter"
        className="custom-drawer"
        onClose={onClose}
        open={open}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button
              style={{ marginRight: 8, width: 101.5 }}
              onClick={handleClearFilter}
            >
              Clear All
            </Button>
            <Button
              type="primary"
              style={{ width: 101.5 }}
              onClick={handleFilter}
            >
              Apply
            </Button>
          </div>
        }
      >
        {resourceName == "gsp-overview" ? (
          <>
            <div
              style={{
                padding: 24,
                borderBottom: "rgba(5, 5, 5, 0.06)1px solid",
              }}
            >
              <div className="div-label">Code</div>
              <div style={{ padding: "5px 0px" }}>
                <Select
                  defaultValue="All"
                  className="dv-select"
                  value={code}
                  onChange={(e) => {
                    onchangeHandleCode(e);
                  }}
                  showSearch
                >
                  {uniqueCode.map((code, index) => (
                    <option key={index} value={code}>
                      {code}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                padding: 24,
                borderBottom: "rgba(5, 5, 5, 0.06)1px solid",
              }}
            >
              <div className="div-label">Model Name</div>
              <div style={{ padding: "5px 0px" }}>
                <Select
                  defaultValue="All"
                  className="dv-select"
                  value={modelName}
                  onChange={(e) => {
                    onchangeHandleModelName(e);
                  }}
                  showSearch
                >
                  {uniqueModelNames.map((modelName, index) => (
                    <option key={index} value={modelName}>
                      {modelName}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                padding: 24,
                borderBottom: "rgba(5, 5, 5, 0.06)1px solid",
              }}
            >
              <div className="div-label">Engine Type</div>
              <div style={{ padding: "5px 0px" }}>
                <Select
                  defaultValue="All"
                  className="dv-select"
                  value={engineType}
                  onChange={(e) => {
                    onchangeHandleEngineType(e);
                  }}
                  showSearch
                >
                  {uniqueEngineType.map((engineType, index) => (
                    <option key={index} value={engineType}>
                      {engineType}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                padding: 24,
                borderBottom: "rgba(5, 5, 5, 0.06)1px solid",
              }}
            >
              <div className="div-label">SAP Code</div>
              <div style={{ padding: "5px 0px" }}>
                <Select
                  defaultValue="All"
                  className="dv-select"
                  value={sapCode}
                  onChange={(e) => {
                    onchangeHandleSapCode(e);
                  }}
                  showSearch
                >
                  {uniqueSapCodes.map((sapCode, index) => (
                    <Option value={sapCode.sapCode} key={sapCode.sapCode}>
                      {sapCode.sapCode}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              style={{
                padding: 24,
                borderBottom: "rgba(5, 5, 5, 0.06)1px solid",
              }}
            >
              <div className="div-label">Fuel Type</div>
              <div style={{ padding: "5px 0px" }}>
                <Select
                  defaultValue="all"
                  className="dv-select"
                  value={fuelType}
                  onChange={(e) => {
                    onchangeHandleFuelType(e);
                  }}
                >
                  {allEngineType.map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </div>
  );
};
