import { message, Row, Select, Table } from "antd";
import { useEffect, useState } from "react";
import apiService from "../../Services/commonServices";
import { listModels, modelDetails } from "../../Services/Endpoints";
import "./ModelDetails.css";
import { useRecoilState } from "recoil";
import { sideNavCollapsedState } from "../../Recoil/recoilState";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Ellipsis } from "react-bootstrap/esm/PageItem";

const { Option } = Select;

const ModelDetailsHeader = ({
	onModelSelect,
	isModelFlag,
	duplicateModelDetail,
	isDuplicateFlag,
	verifyModelChange,
}) => {
	const collapsed = useRecoilState(sideNavCollapsedState);
	const [selectedModel, setSelectedModel] = useState({});
	const [allModels, setAllModels] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [filteredModels, setFilteredModels] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();
	const params = useParams();

	const columns = [
		{
			title: "Model Name",
			dataIndex: "modelName",
			key: "modelName",
			ellipsis: true,
		},
		{
			title: "Factory Code",
			dataIndex: "factoryModelCode",
			key: "factoryModelCode",
			ellipsis: true,
		},
		{
			title: "Version",
			dataIndex: "version",
			key: "version",
			width: "10%",
			ellipsis: true,
		},
		{
			title: "Model Type",
			dataIndex: "modelType",
			key: "modelType",
			width: "15%",
			ellipsis: true,
		},
		{
			title: "Fuel Type",
			dataIndex: "fuelType",
			key: "fuelType",
			width: "13%",
			ellipsis: true,
		},
		{
			title: "Variant",
			dataIndex: "variant",
			key: "variant",
			width: "10%",
			ellipsis: true,
		},
		{
			title: "Engine Type",
			dataIndex: "engineType",
			key: "engineType",
			ellipsis: true,
		},
		{
			title: "GWMS Code",
			dataIndex: "gwmsServiceCode",
			key: "gwmsServiceCode",
			ellipsis: true,
		},
	];

	const dataSource = filteredModels.map((model) => ({
		key: model.id,
		modelName: model.modelName,
		factoryModelCode: model.factoryCode,
		version: model.version,
		modelType: model.modelType?.name,
		fuelType: model.engineType?.name,
		variant: model.modelVariant?.name,
		engineType: model.engineNumber,
		gwmsServiceCode: model.gwmsServiceCode,
	}));

	useEffect(() => {
		setFilteredModels(
			allModels.filter(
				(model) =>
					model.modelName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
					model.factoryCode?.toLowerCase().includes(searchValue?.toLowerCase()) ||
					model.version?.toLowerCase().includes(searchValue?.toLowerCase()) ||
					model.modelType?.name
						?.toLowerCase()
						.includes(searchValue?.toLowerCase()) ||
					model.engineType?.name
						?.toLowerCase()
						.includes(searchValue?.toLowerCase()) ||
					model.modelVariant?.name
						?.toLowerCase()
						.includes(searchValue?.toLowerCase()) ||
					model.engineNumber
						?.toLowerCase()
						.includes(searchValue?.toLowerCase()) ||
					model.gwmsServiceCode
						?.toLowerCase()
						.includes(searchValue?.toLowerCase())
			)
		);
	}, [allModels, searchValue]);

	const onSearch = (value) => {
		setSearchValue(value);
	};

	useEffect(() => {
		fetchModels();
		setDropdownVisible(false);
	}, []);

	useEffect(() => {
		setSelectedModel({});
	}, [isModelFlag]);

	useEffect(() => {
		if (params.id) {
			onGetModelDetails();
		}
	}, [params.id]);

	const fetchModels = async () => {
		try {
			setIsLoading(true);
			const responseData = await apiService.get(listModels);

			const sortedModels = responseData.data.slice().sort((a, b) => {
				const extractParts = (modelName) => {
					if (!modelName) return [""];
					const parts = modelName.match(/([a-zA-Z]+)|(\d+)/g) || [];
					return parts.map((part) =>
						isNaN(part) ? part?.toLowerCase() : parseInt(part, 10)
					);
				};
				const partsA = extractParts(a.modelName) || [];
				const partsB = extractParts(b.modelName) || [];
				for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
					if (partsA[i] !== partsB[i]) {
						if (isNaN(partsA[i]) && isNaN(partsB[i])) {
							return partsA[i].localeCompare(partsB[i]);
						} else if (isNaN(partsA[i])) {
							return -1;
						} else if (isNaN(partsB[i])) {
							return 1;
						} else {
							return partsA[i] - partsB[i];
						}
					}
				}
				return partsA.length - partsB.length;
			});
			setAllModels(sortedModels);
			setIsLoading(false);
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		setSelectedModel(duplicateModelDetail);
	}, [isDuplicateFlag]);

	const onGetModelDetails = async () => {
		const { id } = params;
		try {
			const responseData = await apiService.get(`${modelDetails}${Number(id)}`);
			if (!responseData.data) {
				message.warning("Model not found. Please check the ID.");
			} else {
				setSelectedModel(responseData.data);
				onModelSelect(responseData.data);
			}
		} catch (error) {
			message.warning("Model not found. Please check the ID.");
			console.log(error.message);
		}
	};

	const onSelectModel = async (option) => {
		const currentPath = location.pathname;
		setDropdownVisible(false);
		navigate(`${currentPath.replace(/\/\d+$/, "")}/${option.record.key}`);
	};

	const ModelHeaderDetail = ({ label, value }) => (
		<div className='each-column'>
			<p className='model-header-label'>{label}</p>
			<span className='model-label-data'>{value || "-"}</span>
		</div>
	);

	const headerDetails = [
		{ label: "Model Type", prop: "modelType.name" },
		{ label: "Engine Type", prop: "engineNumber" },
		{ label: "SAP Code", prop: "sapCode" },
		{ label: "Fuel Type", prop: "engineType.name" },
		{ label: "Recommended Service", prop: "recommendedServiceDetails.name" },
		{ label: "Variant", prop: "modelVariant.name" },
		{ label: "GWMS Code", prop: "gwmsServiceCode" },
	];

	const handleDropdownVisibleChange = (visible) => {
		setDropdownVisible(visible);
	};

	return (
		<div
			className='model-details-header'
			style={{ flexDirection: !collapsed && "column" }}>
			<div style={{ marginRight: "24px" }}>
				<p className='model-header-label'>Model</p>
				<Select
					showSearch
					placeholder='Select Model'
					value={selectedModel?.modelName}
					onSearch={onSearch}
					// onChange={(value, option) => onSelectModel(value, option)}
					onDropdownVisibleChange={handleDropdownVisibleChange}
					open={dropdownVisible}
					dropdownRender={(menu) => (
						<div className='custom-dropdown-container'>
							<Table
								columns={columns}
								dataSource={dataSource}
								pagination={false}
								rowKey='id'
								loading={isLoading}
								size='small'
								scroll={{ y: 200 }}
								onRow={(record) => ({
									onClick: () => {
										if (verifyModelChange) {
											verifyModelChange({ onSelectModel, option: { record } });
										} else {
											onSelectModel({ record });
										}
									},
								})}
								bordered
							/>
						</div>
					)}
					popupClassName='custom-dropdown'>
					{filteredModels.map((option) => (
						<Option key={option.id} value={option.modelName} id={option.id}>
							{option.modelName}
						</Option>
					))}
				</Select>
			</div>
			<Row gutter={6} className='model-details-container'>
				{headerDetails.map((detail, index) => (
					<ModelHeaderDetail
						key={index}
						label={detail.label}
						value={getProperty(selectedModel, detail.prop)}
					/>
				))}
			</Row>
		</div>
	);
};

const getProperty = (object, path) => {
	const properties = path.split(".");
	return properties.reduce(
		(obj, prop) => (obj && obj[prop] ? obj[prop] : null),
		object
	);
};

export default ModelDetailsHeader;
