import React, { useState, useEffect } from "react";
import "./gsp-overview.css";
import CommonTable from "../../CommonComponents/Table/CommonTable";
import { Tooltip, Table, Tabs, Modal } from "antd";
import apiService from "../../Services/commonServices";
import {
  getGSPOverViewModelList,exportGspOverview
} from "../../Services/Endpoints";
import saveAs from 'save-as';

const GspOverview = () => {
  const [allModel, setAllModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [allModelDetails, setAllModelDetails] = useState([]);
  const [selectedModel, setSelectedModel] = useState([]);
  const [reimbursementData, setReimbursementData] = useState([]);
  const [customerPaymentData, setCustomerPaymentData] = useState([]);
  const [prepaidServicePlanData, setPrepaidServicePlanData] = useState([]);
  const customerPaymentDataSource = [{}];

  const popupData = {
    popup: {
      title: "GSP Overview",
      header: [
        {
          label: "Code",
          value: selectedModel?.modelDetails?.factoryCode,
        },
        {
          label: "Model Name",
          value: selectedModel?.modelDetails?.modelName,
        },
        {
          label: "Model Type",
          value: selectedModel?.modelDetails?.modelType,
        },
        {
          label: "Variant",
          value: selectedModel?.modelDetails?.modelVariant,
        },
        {
          label: "Engine Type",
          value: selectedModel?.modelDetails?.engineNumber,
        },
        {
          label: "GWMS Code",
          value: selectedModel?.modelDetails?.gwmsServiceCode,
        },
        {
          label: "Recommended Service",
          value: selectedModel?.modelDetails?.recommendedService,
        },
      ],
    },
  };

  customerPaymentData?.data?.forEach((item) => {
    customerPaymentDataSource[0][`${item.km}km`] = {
      months: item.months,
      price: item.amount !== undefined ? `$${item.amount}` : "-",
    };
  });

  const reimbursementDataSource = [{}];

  reimbursementData?.data?.forEach((item) => {
    reimbursementDataSource[0][`${item.km}km`] = {
      months: item.months,
      price: item.amount !== undefined ? `$${item.amount}` : "-",
    };
  });

  customerPaymentDataSource[0]["5yeartotal"] = {
    months: "5 Year Total",
    price: `$${customerPaymentData.totalOfFiveYears}`,
  };

  reimbursementDataSource[0]["5yeartotal"] = {
    months: "5 Year Total",
    price: `$${reimbursementData.totalOfFiveYears}`,
  };

  const columns1 = reimbursementData?.data?.map((item) => ({
    title: `${item.km} KM`,
    className: "gsp-overview-table",
    children: [
      {
        title: `${item.months} Months`,
        dataIndex: `${item.km}km`,
        key: `${item.km}km`,
        className: "gsp-overview-table",
        render: (text, record) => (
          <>
            <div className="right-align">{record[`${item.km}km`]?.price}</div>
          </>
        ),
      },
    ],
  }));

  columns1?.push({
    title: "5 Year Total",
    dataIndex: "5yeartotal",
    key: "5yeartotal",
    className: "col-total",
    fixed: "right",
    render: (text, record) => (
      <>
        <div className="right-align col-total">
          {reimbursementData.totalOfFiveYears}
        </div>
      </>
    ),
  });

  const column2 = prepaidServicePlanData.map((item) => ({
    title: `${item.year} Years`,
    dataIndex: `year${item.year}`,
    key: `year${item.year}`,
    render: (text) => <div className="right-align">{text}</div>,
    className: "gsp-overview-table",
  }));

  const dataSourceTransformed = [
    {
      key: "1",
      ...prepaidServicePlanData.reduce((acc, item) => {
        acc[`year${item.year}`] = `$${item.amount.toFixed(2)}`;
        return acc;
      }, {}),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Reimbursement (Excl. GST)",
      children: (
        <Table
          columns={columns1}
          dataSource={reimbursementDataSource}
          bordered
          pagination={false}
          className="gsp-overview-table"
          scroll={{ x: true }}
        />
      ),
    },
    {
      key: "2",
      label: "Customer Payment (Excl. GST)",
      children: (
        <Table
          columns={columns1}
          dataSource={customerPaymentDataSource}
          pagination={false}
          bordered
          className="gsp-overview-table"
          scroll={{ x: true }}
        />
      ),
    },
    {
      key: "3",
      label: "Prepaid Service Plan Payment (Incl. GST)",
      children: (
        <Table
          columns={column2}
          dataSource={dataSourceTransformed}
          pagination={false}
          bordered
          className="gsp-overview-table custom-table"
          width="40%"
        />
      ),
    },
  ];

  const onChange = () => {};

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [modelResponse] = await Promise.all([
        apiService.get(getGSPOverViewModelList),
      ]);

      const modelData = modelResponse?.data;

      setAllModel(modelData);
      setAllModelDetails(modelData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const exportGSPOverview = async (key,data) => {
    let payload ={};
    const modelIds = data === 'exportAll'
        ? allModelDetails.map(item => ({ modelId: item.modelDetails.id }))
        : [ { modelId: key?.modelDetails?.id } ];

    payload = {
        data: data === 'exportAll' ? modelIds : modelIds,
        fileType: data === 'exportAll' ? key : 'Infomedia'
    };

    try {
      setIsLoading(true);
      const response = await apiService.post(exportGspOverview,payload);
      if(response) {
        const { fileName, workbook: buffer } = response;
        const arrayBuffer = new Uint8Array(buffer.data).buffer;
        const blob = new Blob([arrayBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
        setIsLoading(false);
      }
    }
    catch(error) {
      console.error("error", error.message)
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  const columns = [
    {
      title: "Code",
      dataIndex: "factoryCode",
      render: (_, record) => (
        <span className="record-text" style={{}}>
          {record?.modelDetails?.factoryCode}
        </span>
      ),
      width: 30,
    },
    {
      title: "Model Name",
      dataIndex: "modelName",
      render: (_, record) => (
        <span className="record-text" style={{}}>
          {record?.modelDetails?.modelName}
        </span>
      ),
      width: 85,
      sorter: (a, b) => {
        const nameA = a.modelDetails?.modelName || "";
        const nameB = b.modelDetails?.modelName || "";
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: "Model Type",
      dataIndex: "modelType",
      render: (_, record) => (
        <span className="record-text" style={{}}>
          {record?.modelDetails?.modelType}
        </span>
      ),
      width: 120,
    },
    {
      title: "Variant",
      dataIndex: "modelVariant",
      render: (_, record) => (
        <span className="record-text" style={{}}>
          {record?.modelDetails?.modelVariant}
        </span>
      ),
      width: 80,
    },
    {
      title: "Engine Type",
      dataIndex: "engineNumber",
      render: (_, record) => (
        <span className="record-text" style={{}}>
          {record?.modelDetails?.engineNumber}
        </span>
      ),
      width: 100,
    },
    {
      title: "GWMS Code",
      dataIndex: "gwmsServiceCode",
      render: (_, record) => (
        <span className="record-text" style={{}}>
          {record?.modelDetails?.gwmsServiceCode}
        </span>
      ),
      width: 100,
    },
    {
      title: (
        <Tooltip title="Recommended Service">
          <span className="ellipsis">Recommended Service</span>
        </Tooltip>
      ),
      dataIndex: "recommendedServiceId",
      className: "ellipsis",
      render: (_, record) => (
        <Tooltip title={record?.modelDetails?.recommendedService}>
          <span className="record-text ellipsis" style={{}}>
            {record?.modelDetails?.recommendedService}
          </span>
        </Tooltip>
      ),
      width: 200,

      sorter: (a, b) =>
        a.modelDetails?.recommendedService.localeCompare(
          b.modelDetails?.recommendedService
        ),
    },
  ];

  const onHandleSearch = (e) => {
    const { value } = e.target;
    const filteredItems = allModelDetails.filter((item) => {
      const searchValue = value?.toLowerCase();
      const fields = [
        item.modelDetails.modelName,
        item.modelDetails.factoryCode,
        item.modelDetails.version,
        item.modelType?.name,
        item.modelDetails.engineNumber,
        item.modelDetails.engineType?.name,
        item.recommendedServiceDetails?.name,
        item.modelDetails.modelVariant?.name,
        item?.modelDetails.gwmsServiceCode,
        item.modelDetails.sapCode,
      ];

      return fields.some((field) => field?.toLowerCase().includes(searchValue));
    });
    setAllModel(filteredItems);
  };

  const onHandleFilter = (e) => {
    const enigneType = e.engine_type?.toLowerCase();
    const code = e.code?.toLowerCase();
    const modelName = e.model_Name?.toLowerCase();

    const filteredItems = allModelDetails.filter((item) => {
      const matchesFuelType =
        enigneType === "all" ||
        item?.modelDetails?.engineNumber?.toLowerCase() === enigneType;
      const matchesSapCode =
        code === "all" ||
        item?.modelDetails?.factoryCode?.toLowerCase() === code;
      const matchesModelName =
        modelName === "all" ||
        item?.modelDetails?.modelName?.toLowerCase() === modelName;

      return matchesFuelType && matchesSapCode && matchesModelName;
    });

    setAllModel(filteredItems);
  };

  const handleView = async (record) => {
    setSelectedModel(record);
    try {
      const responseData = await apiService.get(
        `${getGSPOverViewModelList}${record.configurationId}`
      );
      setReimbursementData(responseData?.data?.reimbursementExcGST);
      setCustomerPaymentData(responseData?.data?.customerPaymentExcGST);
      setPrepaidServicePlanData(responseData?.data?.prepaidServicePlanIncGST);
    } catch (error) {}

    setVisible(true);
  };

  const handleExportModelDetails = (record) => {

    exportGSPOverview(record,'exportModel');
  } 

  const onClose = () => {
    setVisible(false);
  };

  const handleExportFormat = (key) => {
    exportGSPOverview(key,'exportAll');
  };

  return (
    <div>
      <CommonTable
        columns={columns}
        dataSource={allModel}
        rowKey="id"
        filterData={onHandleFilter}
        resourceName={"gsp-overview"}
        isLoading={isLoading}
        modelDetails={allModelDetails}
        onChange={onHandleSearch}
        onExport={handleExportModelDetails}
        onView = {handleView}
        selectedFormat={handleExportFormat}
      />

      <Modal
        title="GSP Overview"
        open={visible}
        onCancel={onClose}
        footer={null}
        width={1000}
        className="modal-gsp-overview"
      >
        <div className="gsp-overview-popup">
          <div className="popup-header">
            {popupData.popup.header.map((item, index) => (
              <div key={index}>
                <span className="popup-title">{item.label} </span>
                <div key={index}>{item.value}</div>
              </div>
            ))}
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          style={{ marginTop: "2%" }}
        />
      </Modal>
    </div>
  );
};

export default GspOverview;
