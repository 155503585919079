import React, { useState } from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./confirmModel.css";

const ConfirmDelete = ({ icon, onConfirm, text, label }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    onConfirm();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="confirm-parent-class">
        <div onClick={showModal}>{icon}</div>
        <Modal
          className="custom-model-cofirm-style"
          title={
            <span className="confirm-title">
              <ExclamationCircleOutlined
                style={{ color: "#FAAD14", marginRight: "8px" }}
              />
              Confirm {label}
            </span>
          }
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Yes"
          cancelText="Cancel"
          wrapClassName="modal-container-parent"
          okButtonProps={{
            style: { background: "#002C5F", borderRadius: "2px" },
          }}
          cancelButtonProps={{ style: { borderRadius: "2px" } }}
        >
          <p className="confirm-description">{text}</p>
        </Modal>
      </div>
    </>
  );
};

export default ConfirmDelete;
