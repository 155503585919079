import React from "react";
import { Modal, Button, Table, Tooltip } from "antd";
import "./gspSyncModal.css";

const SyncModal = ({ visible, data, onConfirm, onCancel }) => {
  return (
    <Modal
      className="sync-gsp-modal"
      width={700}
      open={visible}
      title="Sync Data"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          Confirm
        </Button>,
      ]}
      style={{
        maxHeight: "500px",
        overflowY: "hidden",
      }}
    ></Modal>
  );
};

export default SyncModal;
