import "./technical-Configuration.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	Select,
	Table,
	Button,
	Input,
	Form,
	Spin,
	message,
	Switch,
	Row,
	Col,
	Modal,
	Tooltip,
} from "antd";
import {
	getMaxCharsRule,
	onHandleNumericValidation,
	onHandleAlphaNumericValidation,
	requiredRule,
} from "../../CommonComponents/form/Validation";
import {
	CaretRightOutlined,
	CopyOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import apiService from "../../Services/commonServices";
import EmptyTable from "./EmptyTable";
import ConfirmDelete from "../../CommonComponents/modals/ConfirmModal";
import {
	listModels,
	modelDetails,
	serviceIntervalsApi,
	listParts,
	technicalConfiguration,
	listModelsForDuplicate,
	duplicateTechnicalConfiguration,
	listCategories,
} from "../../Services/Endpoints";
import {
	itemType,
	actionType,
} from "../../Services/Constants/variable";
import ModelDetailsHeader from "../../Layouts/Model-Details-Header/ModelDetails";
import confirmPopup from "../../CommonComponents/Confirmation-Popup/Confirm.jsx";

const { Option } = Select;

const TechnicalConfiguration = () => {
	const navigate = useNavigate();
	const { confirm } = Modal;
	const [selectedModel, setSelectedModel] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [showEditButton, setShowEditButton] = useState(false);
	const [fetchedModels, setfetchedModels] = useState([]);
	const [isEmptyPageOpen, setIsEmptyPageOpen] = useState(true);
	const [showSaveButton, setShowSaveButton] = useState(false);
	const [itemTypeSelected, setItemTypeSelected] = useState(itemType[0].name);
	const [recommendedServices, setRecommendedServies] = useState([]);
	const [form] = Form.useForm();
	const [selectForm] = Form.useForm();
	const [selectedItems, setSelectedItems] = useState([]);
	const [index, setIndex] = useState(0);
	const [itemDetail, setItemDetails] = useState([]);
	const [commentFlag, setCommnetFlag] = useState(false);
	const [partFlag, setPartFlag] = useState(false);
	const [dataSource, setDataSource] = useState([]);
	const [count, setCount] = useState(0);
	const [intervalCount, setIntervalCount] = useState(0);
	let flattenedData = [];
	const hourValues = useState({});
	const [modelId, setModelId] = useState(0);
	const [isTechDetails, setIsTechDetails] = useState(true);
	const [Id, setId] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [duplicateModelDetail, setDuplicateModelDetail] = useState();
	const [editData, setEditData] = useState([]);
	const [editDataSource, setEditDataSource] = useState([]);
	const [modelForDuplicate, setModelForDuplicate] = useState([]);
	const [duplicateFlag, setDuplicateFlag] = useState(false);
	const [EditFlag, setEditFlag] = useState(false);
	const [recommendedFlag, setRecommendedFlag] = useState(true);
	const [addItem, setaddItem] = useState(false);
	const [canChangeModel, setCanChangeModel] = useState(true);
	const [filteredModels, setFilteredModels] = useState([]);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	 const [categoryDetails , setCategoryDetails] = useState([]);
	const [bulkActionValues, setBulkActionValues] = useState(false);
	const [onReloadModelDataID, setonReloadModelDataID] = useState({});

	const fetchData = async () => {
		try {
			const responseData = await apiService.get(listModels);
			const data = responseData.data;
			const sortedModels = data.slice().sort((a, b) => {
				const extractParts = (modelName) => {
					if (!modelName) return [""];
					const parts = modelName.match(/([a-zA-Z]+)|(\d+)/g) || [];
					return parts.map((part) =>
						isNaN(part) ? part?.toLowerCase() : parseInt(part, 10)
					);
				};

				const partsA = extractParts(a.modelName);
				const partsB = extractParts(b.modelName);
				for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
					if (partsA[i] !== partsB[i]) {
						if (isNaN(partsA[i]) && isNaN(partsB[i])) {
							return partsA[i].localeCompare(partsB[i]);
						} else if (isNaN(partsA[i])) {
							return -1;
						} else if (isNaN(partsB[i])) {
							return 1;
						} else {
							return partsA[i] - partsB[i];
						}
					}
				}
				return partsA.length - partsB.length;
			});
			setfetchedModels(sortedModels);
		} catch (error) {
			console.error("Error fetching data:", error.message);
		}
	};


	const getAllCategoryItems = async () => {
		try {
			const responseData = await apiService.get(listCategories);
			if(responseData?.data?.length > 0){
				setCategoryDetails(responseData?.data);
			}else{
				setCategoryDetails([]);
			}
		} catch (error) {
			console.error("Error fetching data:", error.message);
		}
	};

	useEffect(() => {
		getAllCategoryItems();
		fetchData();
	}, []);

	const handleModelSelect = async (modelData) => {
		setonReloadModelDataID(modelData.id);
		setIsEmptyPageOpen(false);
		getModelDetails(modelData.id);
		setShowSaveButton(false);
		setShowEditButton(false);
	};

	useEffect(() => {
		if  (onReloadModelDataID && categoryDetails?.length > 0) {
				getModelDetails(onReloadModelDataID);
		}
	}, [categoryDetails,onReloadModelDataID]);
	
	const onSearch = (value) => {
		setSearchValue(value);
	};
	

	const handleDropdownVisibleChange = (visible) => {
		setDropdownVisible(visible);
	};

	const onhandleIntervalAction = (
		value,
		rowIndex,
		columnDataIndex,
		bulk,
		kmsDetails,
		record
	) => {
			setCanChangeModel(false);
			const updatedSelectedItems = dataSource.map((item, index) => {
				if (item.categoryName) {
					return item;
				}

				const updatedKmsDetails = [...(item.kmsDetails || [])];
				recommendedServices.forEach((service) => {
					if (
						!updatedKmsDetails.some(
							(detail) => detail.code === service.serviceIntervalCode
						)
					) {
						updatedKmsDetails.push({
							kmId: service.id,
							code: service.serviceIntervalCode,
							action: null,
							hour: service.hour,
						});
					}
				});
				return {
					...item,
					kmsDetails: updatedKmsDetails,
				};
			});

			let updatedKmsDetails = updatedSelectedItems[rowIndex].kmsDetails.map(
				(detail) => {
					if(bulk){
						return { ...detail, action: value };
					}
					else if (detail.code === columnDataIndex) {
						return { ...detail, action: value };
					}
					return detail;
				}
			);

			recommendedServices.forEach((service) => {
				updatedKmsDetails.find((detail) => {
					if (detail.code === columnDataIndex) {
						detail.action = value;
						return true;
					}
					return false;
				});
			});

			updatedSelectedItems[rowIndex] = {
				...updatedSelectedItems[rowIndex],
				kmsDetails: updatedKmsDetails,
			};
			const filteredData = updatedSelectedItems.filter(
				(item) => !item.categoryName
			);
			setDataSource(updatedSelectedItems);
			setSelectedItems(filteredData);
	};

	const updateKmsDetails = () => {
		const data = selectedItems.filter((item) => item !== undefined);
		const updatedSelectedItems = data.map((item) => {
			// const matchingActionType = actionType?.find(
			// 	(type) => type.id === item.action
			// );

			if (item?.categoryName) {
				return item;
			}

			const existingKmsDetails = item?.kmsDetails || [];
			const existingCodes = new Set(
				existingKmsDetails.map((detail) => detail.code)
			);

			const updatedKmsDetails = [...existingKmsDetails];

			recommendedServices.forEach((service) => {
				const { serviceIntervalCode, id, hour } = service;
				const existingDetail = existingKmsDetails.find(
					(detail) => detail.code === serviceIntervalCode
				);

				if (!existingCodes.has(serviceIntervalCode)) {
					updatedKmsDetails.push({
						kmId: id,
						code: serviceIntervalCode,
						action: existingDetail ? existingDetail.action : null,
						hour: hour,
					});
				} else {
					const existingDetailIndex = updatedKmsDetails.findIndex(
						(detail) => detail.code === serviceIntervalCode
					);
					if (existingDetailIndex !== -1) {
						updatedKmsDetails[existingDetailIndex] = {
							...updatedKmsDetails[existingDetailIndex],
							action: existingDetail ? existingDetail.action : null,
						};
					}
				}
			});
    
			return {
				...item,
				kmsDetails: updatedKmsDetails,
			};
		});

		console.log("updatedSelectedItems", updatedSelectedItems);

		const filteredData = updatedSelectedItems.filter(
			(item) => !item.categoryName
		);

		setSelectedItems(filteredData);
		const flattenedData = getFlattenedData(filteredData);
		setDataSource(flattenedData);
	};

	const handleFinish = (value) => {
		setShowSaveButton(true);
		setaddItem(true);
		setIndex(index + 1);
		form.resetFields();
		setItemTypeSelected(itemType[0].name);
		setCommnetFlag(false);
		setCanChangeModel(false);
		const filteredDataSource = dataSource.filter((item) => !item.categoryName);
		if (filteredDataSource.length > 99) {
			message.error("Maximum limit of 100 items reached.");
			return null;
		} else {
			updateKmsDetails();
			flattenedData = getFlattenedData(selectedItems);
			setDataSource(flattenedData);
		}
		setCount(
			flattenedData.filter((item) => !item?.categoryName && !item.name).length
		);
	};

	const getFlattenedData = (data) => {
		let outputArray = [];
		outputArray.push({ name: "Labour hours" });
		data = data.filter((item) => item !== undefined);
		categoryDetails.forEach((category) => {
				const itemsInCategory = data.filter(
					(item) => item?.category === category?.name
				);

				if (itemsInCategory.length > 0) {
					outputArray.push({ categoryName: category.name });
					outputArray.push(...itemsInCategory);
				}
			}); 

		return outputArray;
	};

	const getLabourRate = (data) => {
		let outputArray = [];
		data = data.filter((item) => item !== undefined);

		if (data.length === 0) {
						// Add default labor rate entry when there are no items
			outputArray.push({ name: "Labour hours" });
		} else {
			// Iterate over categories and add labor rate entry for each category
			categoryDetails.forEach((category) => {
				const itemsInCategory = data.filter(
					(item) => item?.category === category?.name
				);

				if (itemsInCategory.length > 0) {
					outputArray.push({ name: "Labour hours" });
					outputArray.push(...itemsInCategory);
				}
			});
		}

		return outputArray;
	};

	const handleLabourRateChange = (record, e, interval) => {
		setCanChangeModel(false);
		const updatedKms = recommendedServices.map((service) => {
			if (service.serviceIntervalCode === interval.serviceIntervalCode) {
				return {
					...service,
					hour: e,
				};
			}
			return service;
		});

		// Update recommendedServices state with modified hour values
		setRecommendedServies(updatedKms);
		if (updatedKms && updatedKms.length > 0) {
			setRecommendedFlag(true);
		} else {
			setRecommendedFlag(false);
		}
		// Update dataSource with modified hour values
		const updatedDataSource = selectedItems.map((item) => {
			const updatedKmsDetails = item.kmsDetails?.map((detail) => {
				if (detail.code === interval.serviceIntervalCode) {
					return {
						...detail,
						hour: e,
					};
				}
				return detail;
			});
			return {
				...item,
				kmsDetails: updatedKmsDetails,
			};
		});

		// Update dataSource state
		setSelectedItems(updatedDataSource);
	};

	const commonColumns = [
		{
			title: "Labour Instruction",
			dataIndex: "part",
			render: (_, record) => {
				const showCaret = record.frequency || record.isFrequentlyPerformed;
				if (record.categoryName) {
					return (
						<strong>
							{record.categoryName}
							{showCaret && (
								<CaretRightOutlined style={{ marginLeft: "8px" }} />
							)}
						</strong>
					);
				} else if (record.name) {
					return (
						<strong>
							{record.name}
							{showCaret && (
								<CaretRightOutlined style={{ marginLeft: "8px" }} />
							)}
						</strong>
					);
				} else {
					const itemNameContent =
						(record.item_type === itemType[0].name
							? record.labourInstruction
							: record.itemName) || [];

					return itemNameContent.length > 50 ? (
						<Tooltip title={itemNameContent} className='ellipsis-text'>
							<span>
								{itemNameContent.slice(0, 50)}...
								{showCaret && (
									<CaretRightOutlined style={{ marginLeft: "8px" }} />
								)}
							</span>
						</Tooltip>
					) : (
						<span>
							{itemNameContent}
							{showCaret && (
								<CaretRightOutlined style={{ marginLeft: "8px" }} />
							)}
						</span>
					);
				}
			},
			width: "30%",
			fixed: "left",
			className: "part-description-column",
		},
		{
			title: "Part Number",
			dataIndex: "partNumber",
			className: "part-number-column",
			render: (_, record) => {
				if (record.categoryName) {
					return null;
				} else if (record.name) {
					return null;
				} else {
					return record.partNumber;
				}
			},
			width: "12%",
			fixed: "left",
		},
		{
			title: "Quantity",
			dataIndex: "qty",
			render: (_, record) => {
				if (record.categoryName) {
					return null;
				} else if (record.name) {
					return null;
				} else {
					return record.item_type === "Part" ? record.qty : "-";
				}
			},
			width: "10%",
			fixed: "left",
			className: "quantity-column",
		},
	];

	const generateColumns = () => {
		const serviceColumns = recommendedServices?.map((interval, colIndex) => ({
			title: interval.serviceIntervalCode,
			dataIndex: interval.id,
			render: (_, record, index) => {
				if (record.categoryName) {
					return null;
				} else if (record.name) {
					return (
						<Input
							style={{ width: 110 }}
							value={hourValues[interval.id] || interval.hour}
							onChange={(e) =>
								handleLabourRateChange(record, e.target.value, interval)
							}
							onKeyPress={(e) =>
								onHandleNumericValidation(e, form, "itemValue")
							}
							disabled={!duplicateFlag && !showEditButton && !addItem}
						/>
					);
				} else if (record.commentFlag) {
					if (colIndex === 0) {
						return {
							children: (
								<Tooltip title={record.comment || record.commentValue}>
									<span className='spn-comment'>{record.comment || record.commentValue}</span>
								</Tooltip>
							),
							props: {
								colSpan: recommendedServices.length,
							},
						};
					} else {
						return {
							children: null,
							props: {
								colSpan: 0,
							},
						};
					}
				} else {
					const kmsDetails = dataSource[index]?.kmsDetails || [];
					// Find the specific kmsDetail for the current interval
					const kmsDetail = kmsDetails.find(
						(detail) => detail.code === interval.serviceIntervalCode
					);
					// Find the action type id based on the action name
					const selectedActionId = kmsDetail ? kmsDetail.action : undefined;

					return (
						<span className='spn-services'>
							<Select
								placeholder='Action Type'
								value={selectedActionId === null ? "N/A" : selectedActionId}
								dropdownRender={(menu) => (
									<div onClick={(e) => e.preventDefault()}>{menu}</div>
								)}
								onChange={(value) => {
									onhandleIntervalAction(
										value,
										index,
										interval.serviceIntervalCode,
										false,
										kmsDetails,
										record
									);
								}}
								disabled={!duplicateFlag && !showEditButton && !addItem}>
								{actionType.map((option) => (
									<Option key={option.id} value={option.id}>
										{option.name}
									</Option>
								))}
							</Select>
						</span>
					);
				}
			},
			width: "15%",
		}));

		const actionBlukColumn = {
			title: "Bulk Update",
			key: "actions",
			render: (_, record, rowIndex) => {
				if (record.categoryName || record.commentFlag || record.name) {
					return null;
				}

				const handleApplyClick = () => {
					setCanChangeModel(false);
					const selectedValue = bulkActionValues[rowIndex];
					if (selectedValue !== undefined) {
						onhandleIntervalAction(selectedValue, rowIndex, record, true);
					}
				};

				return (
					<span className='spn-services bulk-update'>
						<Select
							placeholder='Action Type'
							value={bulkActionValues[rowIndex] ?? "N/A"}
							onChange={(value) => {
								setCanChangeModel(false);
								setBulkActionValues((prev) => ({
									...prev,
									[rowIndex]: value,
								}));
							}}
							disabled={!duplicateFlag && !showEditButton && !addItem}>
							{actionType.map((option) => (
								<Option key={option.id} value={option.id}>
									{option.name}
								</Option>
							))}
						</Select>
						<button
							className='btn-bulk-apply'
							onClick={handleApplyClick}
							disabled={!duplicateFlag && !showEditButton && !addItem}>
							Apply
						</button>
					</span>
				);
			},
			width: "15%",
		};
		return [actionBlukColumn, ...serviceColumns];
	};

	const ActionColumn = () => [
		{
			title: <span className='action-title'>Action</span>,
			dataIndex: "action",
			fixed: "right",
			render: (_, record) => {
				if (record.categoryName) {
					return null;
				} else if (record.name) {
					return null;
				} else {
					return (
						<ConfirmDelete
							icon={
								<Button
									shape='square'
									className='confirm-button'
									icon={<CloseOutlined />}
								/>
							}
							text={"Are you sure you want to clear the selected record?"}
							label={"Cancel"}
							onConfirm={() => onHandleRowCancel(record)}
						/>
					);
				}
			},
			width: "9%",
		},
	];

	const columns = [...commonColumns, ...generateColumns(), ...ActionColumn()];

	const modelColumns = [
		{
			title: "Model Name",
			dataIndex: "modelName",
			key: "modelName",
		},
		{
			title: "Factory Code",
			dataIndex: "factoryModelCode",
			key: "factoryModelCode",
		},
		{
			title: "Version",
			dataIndex: "version",
			key: "version",
			width: "10%",
		},
		{
			title: "Model Type",
			dataIndex: "modelType",
			key: "modelType",
			width: "15%",
		},
		{
			title: "Fuel Type",
			dataIndex: "fuelType",
			key: "fuelType",
			width: "13%",
		},
		{
			title: "Variant",
			dataIndex: "variant",
			key: "variant",
			width: "10%",
		},
		{
			title: "Engine Type",
			dataIndex: "engineType",
			key: "engineType",
		},
	];

	const onGetIntervalsByModel = async (id) => {
		const fetchIntervalsByModel = await apiService.get(
			`${serviceIntervalsApi}${id}`
		);

		const uniqueIntervals = [
			...new Set(
				fetchIntervalsByModel?.data?.map((item) => ({
					...item.kmMonth,
					hour: item.hour,
				}))
			),
		];
		setIntervalCount(uniqueIntervals.length);
		setRecommendedServies(uniqueIntervals);
		if (uniqueIntervals && uniqueIntervals.length > 0) {
			setRecommendedFlag(true);
		} else {
			setRecommendedFlag(false);
		}
		setIsLoading(false);
	};


	const updateActionValues = (data) => {
		return data.map((item) => {
			if (item.kmsDetails) {
				const updatedKmsDetails = item.kmsDetails.map((detail) => {
					const matchingActionType = actionType.find(
						(type) => type.name === detail.action
					);
					if (matchingActionType) {
						return {
							...detail,
							action: matchingActionType.id,
						};
					}
					return detail;
				});

				return {
					...item,
					kmsDetails: updatedKmsDetails,
				};
			}
			return item;
		});
	};

	const getModelDetails = async (modelId) => {
		setModelId(modelId);

		setIsLoading(true);
		try {
			const responseData = await apiService.get(`${modelDetails}${modelId}`);
			const selectModel = responseData.data;

			setSelectedModel(selectModel);
			onGetIntervalsByModel(modelId);
			const details = await apiService.get(
				`${technicalConfiguration}${modelId}`
			);
			if (details?.data) {
				setIsTechDetails(false);
				const labourRateDataView = getLabourRate(dataSource);
				setSelectedItems(labourRateDataView);
				setDataSource(labourRateDataView);
				setId(details.data.id);
				const editData = transformDataToSelectedData(
					details.data.techDetails,
					details.data.id
				);
				const updatedData = updateActionValues(editData);
				setShowSaveButton(true);
				setIndex(updatedData.length);
				form.resetFields();
				setItemTypeSelected(itemType[0].name);
				setCommnetFlag(false);
				const filteredDataSource = updatedData.filter(
					(item) => !item.categoryName
				);
				if (filteredDataSource.length > 99) {
					message.error("Maximum limit of 100 items reached.");
					return null;
				} else {
					updateKmsDetails();
					flattenedData = getFlattenedData(updatedData);
					setSelectedItems(filteredDataSource);
					setEditDataSource(flattenedData);
					setDataSource(flattenedData);
					setEditData(filteredDataSource);
				}
				if (filteredDataSource.length === 0) {
					setShowEditButton(false);
					setIsTechDetails(true);
					// setEditFlag(true);
				}

				setCount(
					flattenedData.filter((item) => !item.categoryName && !item.name)
						.length
				);

				let selectedAction = [];
				recommendedServices?.map((interval, colIndex) => {
					selectedAction = editData?.find((item) =>
						item.itemDetails?.kmsDetails?.find(
							(detail) => detail.code === interval.serviceIntervalCode
						)
					)?.action;
				});
			} else if (!details?.data) {
				setIsTechDetails(true);
				setShowEditButton(false);
				setDataSource([]);
				setSelectedItems([]);
				const labourRateDataView = [];
				labourRateDataView.push({ name: "Labour hours" });
				setSelectedItems(labourRateDataView);
				setDataSource(labourRateDataView);
				setCount(0);
			} else {
				setSelectedItems(editData);
				setDataSource(editDataSource);
			}
		} catch (error) {
			console.error("Error fetching data:", error.message);
		}
	};

	const onSelectCategory = (value) => {
		const newSelectedItems = [...selectedItems];
		newSelectedItems[index] = { ...selectedItems[index], category: value };
		setSelectedItems(newSelectedItems);
	};

	const onHandleRowCancel = async (recordToRemove) => {
		let updatedDataSource = dataSource.filter(
			(record) => record !== recordToRemove
		);
		const category = recordToRemove.category;
		const itemsInCategory = updatedDataSource.filter(
			(item) => item.category === category
		);
		if (itemsInCategory.length === 0) {
			updatedDataSource = updatedDataSource.filter(
				(item) => item.categoryName !== category
			);
		}
		if (recordToRemove.technicalConfigurationId && recordToRemove.id) {
			try {
				const payload = {
					technicalConfigurationId: recordToRemove.technicalConfigurationId,
					techDetailsId: recordToRemove.id,
				};
				const response = await apiService.delete(
					technicalConfiguration,
					payload
				);
				getModelDetails(modelId);
				message.success(response.message);
				updatedDataSource = updatedDataSource.filter(
					(item) => item !== undefined
				);
				setCount(updatedDataSource.filter((item) => !item.categoryName).length);
				setDataSource(updatedDataSource);
				setSelectedItems(updatedDataSource);
			} catch (error) {
				console.error("Error:", error.message);
				message.error(error.message);
			}
		} else {
			message.success("Technical Configuration deleted successfully");
			setCount(updatedDataSource.filter((item) => !item.categoryName).length);
			setDataSource(updatedDataSource);
			setSelectedItems(updatedDataSource);
		}
		setIsLoading(false);
	};

	const onEditButtonClick = () => {
		setEditFlag(true);
		setIsTechDetails(true);
		setShowEditButton(true);
	};

	const onHandleSubmitIntervals = async () => {
		setPartFlag(false);
		setIsLoading(true);
		const filteredItems = selectedItems.filter(
			(item) => !item.hasOwnProperty("name")
		);
		const techDetailsArray = [];
		filteredItems.forEach(async (item) => {
			const techDetail = {
				id: item.id,
				category: item.category,
				comment: item.comment,
				frequency: item.frequency,
				labourInstruction: item.labourInstruction || null,
			};
			if (item.item_type === itemType[1].name) {
				techDetail.labourInstruction = item.itemName;
				techDetail.itemDetails = {
					kmsDetails: item.kmsDetails,
				};
			} else {
				techDetail.itemId = item.itemId;

				techDetail.itemDetails = {
					name: item.part,
					code: item.partNumber,
					kmsDetails: item.kmsDetails,
				};
				techDetail.qty = parseFloat(item.qty);
			}
			techDetailsArray.push(techDetail);
		});

		const payload = {
			modelId: selectedModel.id,
			techDetails: techDetailsArray,
			id: EditFlag ? Id : 0,
		};
		try {
			const response = duplicateFlag
				? await apiService.post(
						`${duplicateTechnicalConfiguration}${modelId}`,
						payload
				  )
				: await apiService.post(technicalConfiguration, payload);

			onHandleCancel();
			selectForm.resetFields();
			navigate("/technical-configuration");
			message.success(response.message);
			setDuplicateFlag(false);
			getModelDetails(modelId);
			setCanChangeModel(true);
		} catch (error) {
			console.error("Error:", error.message);
			message.error(error.message);
		}
		setIsLoading(false);
	};

	const onHandleCancel = () => {
		setDataSource([]);
		setSelectedItems([]);
		selectForm.resetFields();
		setCount(0);
		setIntervalCount(0);
		setShowEditButton(false);
	};

	const onHandleEditCancelButton = () => {
		setDataSource(editDataSource);
		setSelectedItems(editData);
	};

	const modelDataSource = filteredModels.map((model) => ({
		id: model.id,
		modelName: model.modelName,
		factoryModelCode: model.factoryCode,
		version: model.version,
		modelType: model.modelType?.name,
		fuelType: model.engineType.name,
		variant: model.modelVariant?.name,
		engineType: model.engineNumber,
	}));

	const onSelectItemType = async (value) => {
		setPartFlag(value === "Part");

		setItemTypeSelected(value);
		const newSelectedItems = [...selectedItems];
		newSelectedItems[index] = { item_type: value };
		setSelectedItems(newSelectedItems);
		try {
			const responseData = await apiService.get(listParts);
			// Sort responseData.data alphabetically by itemDescription
			const sortedData = responseData.data.sort((a, b) => {
				if (a.itemDescription < b.itemDescription) {
					return -1;
				}
				if (a.itemDescription > b.itemDescription) {
					return 1;
				}
				return 0;
			});
			setItemDetails(sortedData);
		} catch (error) {
			console.error("Error fetching data:", error.message);
		}
	};

	const transformDataToSelectedData = (data, id) => {
		return data.map((item) => {
			const commentValue = item.comment === null ? false : item.comment;
			if (!item?.itemId) {
				return {
					itemName: item.labourInstruction,
					kmsDetails: item.itemDetails.kmsDetails,
					category: item.category,
					commentFlag: commentValue,
					frequency: item.isFrequentlyPerformed,
					commentValue: item.comment,
					technicalConfigurationId: item.techId,
					id: item.id,
					itemId: item.itemId,
					item_type: "Labour Instruction",
				};
			} else {
				return {
					item_type: "Part",
					part: item.itemDetails.name,
					partNumber: item.itemDetails.code,
					labourInstruction: item.labourInstruction,
					qty: parseFloat(item.qty).toFixed(2),
					category: item.category,
					kmsDetails: item.itemDetails.kmsDetails,
					comment: item.comment,
					commentFlag: commentValue,
					frequency: item.isFrequentlyPerformed,
					technicalConfigurationId: item.techId,
					id: item.id,
					itemId: item.itemId,
				};
			}
		});
	};

	const onSelectPart = async (value, option) => {
		const newSelectedItems = [...selectedItems];
		newSelectedItems[index] = {
			...selectedItems[index],
			part: option.id.itemDescription,
			partNumber: option.id.itemCode,
			itemId: option.id.id,
		};
		setSelectedItems(newSelectedItems);
	};

	const onHandleComment = (e) => {
		setCommnetFlag(e);
		const newSelectedItems = [...selectedItems];
		newSelectedItems[index] = { ...selectedItems[index], commentFlag: e };
		setSelectedItems(newSelectedItems);
	};

	const handleQuantity = (e) => {
		const { value } = e.target;
		const maxChars = 2;

		if (value.length >= maxChars) {
			e.preventDefault();
		}

		const formattedValue = parseFloat(e.target.value).toFixed(2);
		const newSelectedItems = [...selectedItems];
		newSelectedItems[index] = {
			...selectedItems[index],
			qty: formattedValue,
		};
		setSelectedItems(newSelectedItems);
	};

	const handleComment = (e) => {
		const newSelectedItems = [...selectedItems];
		newSelectedItems[index] = {
			...selectedItems[index],
			comment: e.target.value,
		};
		setSelectedItems(newSelectedItems);
	};

	const handleLabourInstruction = (e) => {
		const newSelectedItems = [...selectedItems];
		newSelectedItems[index] = {
			...selectedItems[index],
			labourInstruction: e.target.value,
		};
		setSelectedItems(newSelectedItems);
	};

	const handlePaste = (e) => {
		const paste = (e.clipboardData || window.clipboardData).getData("text");
		if (!/^\d+$/.test(paste)) {
			e.preventDefault();
			form.setFields([
				{
					name: "qty",
					errors: ["Numeric values only."],
				},
			]);
		}
	};

	const handleItemName = (e) => {
		onHandleAlphaNumericValidation(e.target.value, form, "itemName");
		const newSelectedItems = [...selectedItems];
		newSelectedItems[index] = {
			...selectedItems[index],
			itemName: e.target.value,
		};
		setSelectedItems(newSelectedItems);
	};

	const handlePerformFrequency = (e) => {
		const newSelectedItems = [...selectedItems];
		newSelectedItems[index] = { ...selectedItems[index], frequency: e };
		setDataSource(newSelectedItems);
		setSelectedItems(newSelectedItems);
	};

	const handleModalCancel = () => {
		setShowModal(false);
		setDuplicateModelDetail([]);
	};

	const handleConfirmDuplicate = () => {
		setDuplicateFlag(true);
		setSelectedModel(duplicateModelDetail);
		setShowModal(false);
		setShowEditButton(false);
		setIsTechDetails(true);
		selectForm.setFieldsValue({ model: duplicateModelDetail.modelName });
		message.success("Technical Configuration successfully duplicated!");
	};

	const handleDuplicate = () => {
		confirm({
			title: "Confirm Duplication",
			content: `Are you sure you want to proceed with duplicating Technical Configuration of '${selectedModel.modelName}' model to '${duplicateModelDetail.modelName}' model?`,
			okText: "Yes",
			okType: "danger",
			cancelText: "Cancel",
			icon: <ExclamationCircleOutlined />,
			className: "confirm-duplicate",
			width: "440px",
			onOk() {
				handleConfirmDuplicate();
			},
			onCancel() {
				handleModalCancel();
			},
		});
	};

	const handleDuplicateModelDetail = (value, option) => {
		const details = fetchedModels.find((item) => item.id === value.id);
		setDuplicateModelDetail(details);
		setDropdownVisible(false);
	};

	const handleDuplicateModelList = async () => {
		setShowModal(true);
		try {
			const responseData = await apiService.get(
				`${listModelsForDuplicate}${modelId}`
			);
			const data = responseData.data;
			setModelForDuplicate(data);
		} catch (error) {
			console.error("Error fetching data:", error.message);
		}
	};

	const verifyModelChange = ({ onSelectModel, option }) => {
		if (canChangeModel) {
			onSelectModel(option);
			setCanChangeModel(true);
		} else {
			const contentMsg = "Are you sure you want to discard the changes?";
			const icon = <ExclamationCircleOutlined />;
			const label = "Discard";
			confirmPopup({
				label,
				icon,
				contentMsg,
				onOKFunction: () => {
					onSelectModel(option);
					setCanChangeModel(true);
				},
			});
		}
	};

	useEffect(() => {
		setFilteredModels(
			modelForDuplicate.filter(
				(model) =>
					model.modelName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
					model.factoryCode
						?.toLowerCase()
						.includes(searchValue?.toLowerCase()) ||
					model.version?.toLowerCase().includes(searchValue?.toLowerCase()) ||
					model.modelType?.name
						?.toLowerCase()
						.includes(searchValue?.toLowerCase()) ||
					model.engineType.name
						?.toLowerCase()
						.includes(searchValue?.toLowerCase()) ||
					model.modelVariant?.name
						?.toLowerCase()
						.includes(searchValue?.toLowerCase())
			)
		);
	}, [modelForDuplicate, searchValue]);

	return (
		<div className='dv-main-bg'>
			<div
				style={{
					marginLeft: "12px",
					marginRight: "12px",
					marginBottom: "18px",
				}}>
				<ModelDetailsHeader
					verifyModelChange={verifyModelChange}
					onModelSelect={handleModelSelect}
					isDuplicateFlag={duplicateFlag}
					duplicateModelDetail={duplicateModelDetail}
				/>
				{recommendedFlag ? (
					<div>
						<div className='d-add-item'>
							<div style={{ marginBottom: 8 }}>Add Item</div>
							<Form
								form={form}
								onFinish={handleFinish}
								layout='vertical'
								disabled={!isTechDetails || isEmptyPageOpen}>
								<div className='service-add-item'>
									<Row gutter={[16, 16]} align='middle'>
										<Col xs={24} sm={12} md={8} lg={5} xl={4}>
											<Form.Item
												name='itemType'
												label='Item Type'
												rules={[
													{
														required: true,
														message: "Please select an option.",
													},
												]}>
												<Select
													placeholder='Select Item Type'
													onChange={(value, option) => onSelectItemType(value)}>
													{itemType.map((option) => (
														<Option
															key={option.id}
															value={option.name}
															id={option.id}>
															{option.name}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col span={itemTypeSelected === itemType[1].name ? 8 : 5}>
											<Form.Item
												name={
													itemTypeSelected === itemType[1].name
														? "itemName"
														: "part"
												}
												label={
													itemTypeSelected === itemType[1].name
														? "Item Name"
														: "Part"
												}
												rules={[
													{
														required: true,
														message: ` ${
															itemTypeSelected === itemType[1].name
																? "This field is required."
																: "Please select an option."
														}`,
													},
													getMaxCharsRule("", 100),
												]}>
												{itemTypeSelected === itemType[1].name ? (
													<Input
														placeholder='Select or enter Item Name'
														onChange={(e) => {
															handleItemName(e);
														}}
														onKeyDown={(e) => {
															onHandleAlphaNumericValidation(
																e,
																form,
																"itemName"
															);
														}}
														autoComplete='off'
													/>
												) : (
													<Select
														showSearch
														placeholder='Select Part'
														onChange={(value, option) =>
															onSelectPart(value, option)
														}>
														{itemDetail.map((option) => (
															<Option
																key={option.id}
																value={`${option.itemCode} - ${option.itemDescription}`}
																id={option}>
																{option.itemCode} - {option.itemDescription}
															</Option>
														))}
													</Select>
												)}
											</Form.Item>
										</Col>
										{itemTypeSelected === itemType[0].name ? (
											<Col xs={24} sm={12} md={6} lg={4} xl={3}>
												<Form.Item
													name='qty'
													label='Quantity'
													rules={[
														{
															required: true,
															message: "This field is required.",
														},
													]}>
													<Input
														placeholder='Enter Quantity'
														onKeyPress={(e) =>
															onHandleNumericValidation(e, form, "qty")
														}
														onPaste={handlePaste}
														onChange={(e) => {
															handleQuantity(e);
															onHandleNumericValidation(e, form, "qty");
														}}
														autoComplete='off'
													/>
												</Form.Item>
											</Col>
										) : (
											""
										)}
										<Col xs={24} sm={12} md={8} lg={3} xl={2}>
											<Form.Item
												name='comment'
												label='Comment'
												valuePropName='checked'
												initialValue={false}
												rules={[
													{
														required: true,
														message: "This field is required.",
													},
												]}>
												<Switch
													checkedChildren='Yes'
													unCheckedChildren='No'
													onChange={(e) => {
														onHandleComment(e);
													}}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={12} md={7} lg={4} xl={3}>
											<Form.Item
												name='category'
												label='Add to Category'
												rules={[
													{
														required: true,
														message: "Please select an option.",
													},
												]}>
												<Select
													placeholder='Select Category'
													onChange={(value, option) => onSelectCategory(value)}>
													{Array.isArray(categoryDetails) &&
													 categoryDetails.map((option) => (
														<Option key={option.id} 
														        value={option.name} 
														        id={option.id}>
																{option.name}
														</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={24} sm={12} md={8} lg={3} xl={3}>
											<Form.Item
												name='performFrequently'
												label='Severe Usage'
												valuePropName='checked'
												initialValue={false}
												rules={[
													{
														required: true,
														message: "This field is required.",
													},
												]}>
												<Switch
													checkedChildren='Yes'
													unCheckedChildren='No'
													onChange={(e) => {
														handlePerformFrequency(e);
													}}
												/>
											</Form.Item>
										</Col>
										<>
											{partFlag && !commentFlag && (
												<Col xs={24} sm={12} md={12} lg={12} xl={12}>
													<Form.Item
														name='LabourInstructions'
														label='Enter Labour Instructions'
														rules={[requiredRule]}>
														<Input
															onChange={(e) => {
																handleLabourInstruction(e);
															}}
															autoComplete='off'></Input>
													</Form.Item>
												</Col>
											)}

											{partFlag && commentFlag && (
												<>
													<Col xs={24} sm={12} md={10} lg={10} xl={10}>
														<Form.Item
															name='LabourInstructions'
															label='Enter Labour Instructions'
															rules={[
																{
																	required: true,
																	message: "This field is required.",
																},
																getMaxCharsRule("", 100),
															]}
															required>
															<Input
																onChange={(e) => {
																	handleLabourInstruction(e);
																}}
																autoComplete='off'></Input>
														</Form.Item>
													</Col>
													<Col xs={24} sm={12} md={10} lg={10} xl={10}>
														<Form.Item
															name='ActionType'
															label='Enter Comments'
															rules={[
																{
																	required: true,
																	message: "This field is required.",
																},
																getMaxCharsRule("", 100),
															]}
															required>
															<Input
																onChange={(e) => {
																	handleComment(e);
																}}
																autoComplete='off'></Input>
														</Form.Item>
													</Col>
												</>
											)}

											{!partFlag && commentFlag && (
												<Col xs={24} sm={12} md={12} lg={12} xl={12}>
												<Form.Item
													name='ActionType'
													label='Enter Comments'
													rules={[
														{
															required: true,
															message: "This field is required.",
														},
														getMaxCharsRule("", 100),
													]}
													required>
													<Input
														onChange={(e) => {
															handleComment(e);
														}}
														autoComplete='off'></Input>
												</Form.Item>
											</Col>
											)}
										</>

										<Col span={2} style={{ display: "flex" }}>
											<ConfirmDelete
												icon={
													<Button
														style={{ borderRadius: "2px" }}
														className='cancel-button'>
														Reset
													</Button>
												}
												text={"Are you sure you want to reset all fields?"}
												label={"Discard"}
												onConfirm={() => {
													form.resetFields();
													setCommnetFlag(false);
													message.success("All fields have been reset");
												}}
											/>
											<Button
												className='save-button'
												type='primary'
												htmlType='submit'
												style={{ marginLeft: 20 }}>
												Add Item
											</Button>
										</Col>
									</Row>
								</div>
							</Form>
						</div>
						{isEmptyPageOpen ? (
							<EmptyTable />
						) : isLoading ? (
							<div className='spinner-cont'>
								<Spin />
							</div>
						) : (
							<div className='d-add-item'>
								<div style={{ marginBottom: 8 }}>
									Items for Technical Configuration
								</div>
								<div className='service-interval-input-conent'>
									<div className='import-content'>
										{!isEmptyPageOpen ? (
											<div>
												<span className='total-applied-intervals'>
													Total Items :{" "}
												</span>
												<span className='no-of-intrervals'>{count}</span>
											</div>
										) : (
											<div></div>
										)}
									</div>

									<div style={{ padding: 24 }} className='services-table'>
										<Table
											bordered
											dataSource={dataSource}
											columns={columns}
											rowClassName={(record) =>
												record.categoryName
													? "category-row"
													: record.name
													? "labourRate-row"
													: ""
											}
											showHeader={true}
											scroll={{
												x:
													intervalCount <= 3
														? 1000
														: intervalCount <= 5
														? 1700
														: intervalCount < 8
														? 1800
														: intervalCount * 200,
											}}
											pagination={false}
											className='tbl'
										/>
									</div>
								</div>
							</div>
						)}

						<div>
							{!isEmptyPageOpen && showSaveButton && count !== 0 && (
								<div className='save-header-bg'>
									<div className='d-save-cancel-footer'>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}>
											<div className=''>
												<span className='total-applied-intervals'>
													Total applied Intervals :
												</span>
												<span className='no-of-intrervals'>
													{` ${intervalCount} Intervals`}
												</span>
											</div>
											<div className='buttons-cont'>
												{!showEditButton ? (
													!isTechDetails ? (
														<Button
															style={{ borderRadius: "2px" }}
															className='cancel-button'
															onClick={() => handleDuplicateModelList()}>
															<CopyOutlined /> Duplicate
														</Button>
													) : (
														<ConfirmDelete
															icon={
																<Button
																	style={{ borderRadius: "2px" }}
																	className='cancel-button'>
																	Cancel
																</Button>
															}
															text={"Are you sure you want to cancel?"}
															label={"Clear"}
															onConfirm={() => onHandleCancel()}
														/>
													)
												) : (
													<ConfirmDelete
														icon={
															<Button
																style={{ borderRadius: "2px" }}
																className='cancel-button'>
																Cancel
															</Button>
														}
														text={
															"Are you sure you want to discard all the changes?"
														}
														label={"Discard"}
														onConfirm={() => onHandleEditCancelButton()}
													/>
												)}
												{!showEditButton ? (
													!isTechDetails ? (
														<Button
															className='save-button'
															type='primary'
															onClick={onEditButtonClick}>
															Edit Technical Configuration
														</Button>
													) : (
														<Button
															className='save-button'
															type='primary'
															onClick={onHandleSubmitIntervals}>
															Save Technical Configuration
														</Button>
													)
												) : (
													<Button
														className='save-button'
														type='primary'
														onClick={onHandleSubmitIntervals}>
														Update Technical Configuration
													</Button>
												)}
											</div>
										</div>
										<div className='d-note'>
											{" "}
											Note: Items marked with{" "}
											<span className='spn-note'>
												{<CaretRightOutlined />}
											</span>{" "}
											in frequent column should be performed more frequently in
											accordance to the demanding driving / high usage
											conditions listed in the owner's manual
										</div>
									</div>
								</div>
							)}
							<Modal
								title='Duplicate Technical Configuration'
								open={showModal}
								onOk={handleDuplicate}
								onCancel={handleModalCancel}
								okText='Duplicate'
								centered
								className='duplicate-custom-modal'
								width={780}>
								<div style={{ marginTop: 24 }}>
									Select the new model to which you intend to apply the
									duplicated technical configuration
								</div>

								<div style={{ marginTop: 16 }}>
									<Form layout='vertical'>
										<Form.Item label='Select Model' required>
											<Select
												showSearch
												placeholder='Select Model'
												value={duplicateModelDetail?.modelName}
												onSearch={onSearch}
												onDropdownVisibleChange={handleDropdownVisibleChange}
												open={dropdownVisible}
												dropdownRender={(menu) => (
													<div className='custom-dropdown-container'>
														<Table
															columns={modelColumns}
															dataSource={modelDataSource}
															pagination={false}
															size='small'
															rowKey='id'
															scroll={{ y: 200 }}
															onRow={(record) => ({
																onClick: () => {
																	handleDuplicateModelDetail(record);
																},
															})}
															bordered
														/>
													</div>
												)}
												popupClassName='custom-dropdown'>
												{filteredModels.map((option) => (
													<Option
														key={option.id}
														value={option.modelName}
														id={option.id}>
														{option.modelName}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Form>
								</div>
							</Modal>
						</div>
					</div>
				) : (
					<div
						style={{
							marginTop: 24,
						}}>
						<div className='tech-header'>
							<div className='dv-message'>
								Service intervals for this model have not been configured on the
								Model Service Interval page. As a result, no service intervals
								can be viewed on this page. Please complete the configuration
								for this model on the Model Service Interval page to display the
								respective service intervals. Please follow the link-{" "}
								<Link
									to={`/model-service-interval/${modelId}`}
									style={{ color: "#002C5F" }}>
									“Model Service Interval”
								</Link>{" "}
								to configure Model Service Interval.
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TechnicalConfiguration;
