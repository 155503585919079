import React, { useState, useEffect } from "react";
import { Select, Table, Button, Input, message } from "antd";
import { CloseOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import "./modelServiceInterval.css";
import apiService from "../../Services/commonServices";
import EmptyTable from "./EmptyTable";
import ConfirmDelete from "../../CommonComponents/modals/ConfirmModal";
import {
  listRSAdealerPayments,
  getLabourRatesByFuelType,
  serviceIntervalsApi,
  getRecomendedKmMonths,
} from "../../Services/Endpoints";
import { commonAlphanumericSorter, commonSorter } from "../../CommonComponents/SortingLogic";
import ModelDetailsHeader from "../../Layouts/Model-Details-Header/ModelDetails";
import confirmPopup from "../../CommonComponents/Confirmation-Popup/Confirm";

const { Option } = Select;

const ModelServiceInterval = ({ onCancel }) => {
  const [selectedModel, setSelectedModel] = useState({});
  const [newServiceIntervals, setNewServiceIntervals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showActions, setShowActions] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [isEmptyPageOpen, setIsEmptyPageOpen] = useState(true);
  const [labourRates, setlaboutRates] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [rsaDealerPayments, setRsaDealerPayments] = useState([]);
  const [allIntervals, setAllIntervals] = useState([]);
  const [configuredIntervalsBe, setCofiguredIntervalsBe] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [disableCancel, setDisableCancel] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [canModelChange, setCanModelChange]= useState(true)

  const verifyIntervals = (intervalsData) => {
    const modelServiceIntervals = intervalsData.filter(
      (eachInterval) =>
        eachInterval.labourHours !== "" ||
        eachInterval.labourRateId !== "" ||
        eachInterval.sundryId !== ""
    );
    return modelServiceIntervals;
  };

  useEffect(() => {
    fetchData();
  }, []);



  useEffect(() => {
    if (!isEmptyPageOpen) {
      let count = 0;
      const validateIntervals = newServiceIntervals.filter(
        (item) => item?.isValidateInput === true
      );
      if (validateIntervals.length > 0) {
        validateIntervals.forEach((item) => {
          if (
            item.isValidateInput &&
            item.labourHours !== "" &&
            item.labourRateId !== "" &&
            item.sundryId !== ""
          ) {
            count++;
          }
        });

        if (count === validateIntervals.length) {
          setDisableSave(false);
        } else {
          setDisableSave(true);
        }
      }
    } else {
      setDisableSave(true);
    }
  }, [newServiceIntervals, disableSave, isEmptyPageOpen]);

  useEffect(() => {
    if (selectedModel?.id) {
      onGetIntervalsByModel(selectedModel.id);
    }
  }, [allIntervals]);

  const fetchData = async () => {
    try {
      const fetchDealerPayments = await apiService.get(listRSAdealerPayments);
      const sortedData = commonAlphanumericSorter(fetchDealerPayments.data, "code");
      setRsaDealerPayments(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const createIntervals = (serviceIntervalsData) => {
    if (serviceIntervalsData.length === 0) {
      const newIntervals = allIntervals.map((eachInterval) => ({
        id: uuidv4(),
        modelId: selectedModel?.id,
        kmMonthId: eachInterval.id,
        interval: eachInterval?.serviceIntervalCode,
        labourHours: "",
        labourRateId: "",
        sundryId: "",
        status: "NS",
        isEditing: false,
        isValidateInput: false,
        key: uuidv4(),
      }));
      setNewServiceIntervals(newIntervals);
      setShowEditButton(false);
      setShowSaveButton(true);
      setDisableSave(true);
      setDisableCancel(true);
    } else {
      const newIntervals = serviceIntervalsData.map((eachInterval) => ({
        id: eachInterval.id,
        modelId: eachInterval.modelId,
        kmMonthId: eachInterval.kmMonth.id,
        interval: eachInterval.kmMonth.serviceIntervalCode,
        labourHours: eachInterval.hour,
        labourRateId: eachInterval.labourRate.id,
        sundryId: eachInterval.sundry.id,
        status: "S",
        isEditing: false,
        isValidateInput: false,
        key: uuidv4(),
      }));
      setNewServiceIntervals(newIntervals);
      setShowActions(true);
      setShowEditButton(true);
      setShowSaveButton(false);
      setDisableSave(true);
    }
  };

  const onGetIntervalsByModel = async (id) => {
    try {
      const fetchIntervalsByModel = await apiService.get(
        `${serviceIntervalsApi}${id}`
      );
      setCofiguredIntervalsBe(fetchIntervalsByModel?.data);
      setIsLoading(false);
      createIntervals(fetchIntervalsByModel?.data);
    } catch (error) {}
  };



  const verifyModelChange = ({ onSelectModel, option }) => {
    if (canModelChange) {
      onSelectModel(option);
      setCanModelChange(true);
    } else {
      const contentMsg = "Are you sure you want to discard the changes?";
      const icon = <ExclamationCircleOutlined />;
      const label = "Discard";
      confirmPopup({
        label,
        icon,
        contentMsg,
        onOKFunction: () => {
          onSelectModel(option);
          setCanModelChange(true);
        },
      });
    }
  };



  const onSelectModel = async (modelData) => {
    setIsEmptyPageOpen(false);
    setIsLoading(true);
    try {
      setSelectedModel(modelData);
      const fetchIntervals = await apiService.get(
        `${getRecomendedKmMonths}${modelData?.recommendedServiceDetails?.id}`
      );
      setAllIntervals(fetchIntervals.data);

      const fetchlabourrate = await apiService.get(
        `${getLabourRatesByFuelType}${modelData?.engineType?.name}`
      );
      setlaboutRates(fetchlabourrate.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleLabourHoursChange = (e, id) => {
    setCanModelChange(false)
    setDisableCancel(false);
    let { value } = e.target;
    const reg = /^\d{0,10}(\.\d{0,2})?$/;
    if (/^[0-9.]*$/.test(value)) {
      if (value.length <= 10) {
        if (reg.test(value) || value === "") {
          if (value.indexOf(".") !== -1) {
            if (value.split(".")[0].length === 0) {
              setFormErrors({ id, value: "Numeric values only." });
              return;
            }
          }
          setDisableCancel(false);
          setNewServiceIntervals((prevIntervals) => {
            const updatedIntervals = prevIntervals.map((interval) =>
              interval.id === id
                ? {
                    ...interval,
                    labourHours: value,
                    isValidateInput: true,
                    hasZero: parseFloat(value) !== 0 && false,
                  }
                : interval
            );
            return updatedIntervals;
          });
          setFormErrors((prevErrors) => ({
            id: "",
            value: "",
          }));
        } else {
          setFormErrors({ id, value: "Max 2 decimals allowed." });
        }
      } else {
        setFormErrors({ id, value: "Max 10 characters." });
      }
    } else {
      setFormErrors({ id, value: "Numeric values only." });
    }
  };

  const handleLabourRateChange = (value, record, option) => {
    setCanModelChange(false)
    setDisableCancel(false);
    setNewServiceIntervals((prevIntervals) => {
      const updatedIntervals = prevIntervals.map((interval) =>
        interval.id === record.id
          ? { ...interval, labourRateId: option.id, isValidateInput: true }
          : interval
      );
      setFormErrors({});
      return updatedIntervals;
    });
  };

  const handleRSAChange = (value, record, option) => {
    setCanModelChange(false)
    setDisableCancel(false);
    setNewServiceIntervals((prevIntervals) => {
      const updatedIntervals = prevIntervals.map((interval) =>
        interval.id === record.id
          ? { ...interval, sundryId: option.id, isValidateInput: true }
          : interval
      );
      setFormErrors({});
      return updatedIntervals;
    });
  };

  const onHandleRowCancel = (record) => {
    const updatedIntervals = newServiceIntervals.map((interval) =>
      interval.id === record.id
        ? {
            ...interval,
            status: "NS",
            labourHours: "",
            labourRateId: "",
            sundryId: "",
            isValidateInput: false,
            hasZero: false,
          }
        : interval
    );
    setNewServiceIntervals(updatedIntervals);
    setDisableSave(true);

    const editedIntervals = verifyIntervals(updatedIntervals);
    if (editedIntervals.length === 0) {
      setDisableCancel(true);
    } else {
      setDisableCancel(false);
    }
  };

  const onHandleSubmitIntervals = async () => {
    try {
      setIsLoading(true);
      setFormErrors({});
      const removeServiceIntervals = newServiceIntervals
        .filter(
          (elem) => !configuredIntervalsBe?.find(({ id }) => elem.id === id)
        )
        .filter(
          (eachInterval) =>
            eachInterval.labourHours !== undefined &&
            eachInterval.labourRateId !== "" &&
            eachInterval.sundryId !== ""
        )
        .map((eachInterval) => ({
          modelId: selectedModel.id,
          kmMonthId: eachInterval.kmMonthId,
          hour: parseFloat(eachInterval.labourHours),
          labourRateId: eachInterval.labourRateId,
          sundryId: eachInterval.sundryId,
        }));

      const response = await apiService.post(
        serviceIntervalsApi,
        removeServiceIntervals
      );
      message.success(response.message);
      setCanModelChange(true)
    } catch (error) {
      message.error(error.message);
    }
    onGetIntervalsByModel(selectedModel.id);
  };

  const onHandleCancelIntervals = () => {
    const newData = newServiceIntervals.map((eachInterval) => {
      return {
        ...eachInterval,
        labourHours: "",
        labourRateId: "",
        sundryId: "",
        status: "NS",
        isValidateInput: false,
        hasZero: false,
      };
    });
    setNewServiceIntervals(newData);
    setDisableCancel(true);
    setDisableSave(true);
  };

  const onHandleEditIntervals = () => {
    setIsLoading(true);
    setFormErrors({});
    const editedServiceInterval = newServiceIntervals.filter((elem) =>
      configuredIntervalsBe?.find(({ id }) => elem.id === id)
    );

    const editingIntervals = editedServiceInterval.map((eachInterval) => ({
      ...eachInterval,
      status: "NS",
      isEditing: true,
      isValidateInput: false,
    }));
    const restIntervals = allIntervals.filter(
      (elem) =>
        !editingIntervals?.find(({ kmMonthId }) => elem.id === kmMonthId)
    );

    const missingIntervals = restIntervals.map((eachInterval) => ({
      id: uuidv4(),
      modelId: selectedModel?.id,
      kmMonthId: eachInterval.id,
      interval: eachInterval?.serviceIntervalCode,
      labourHours: "",
      labourRateId: "",
      sundryId: "",
      status: "NS",
      isEditing: false,
      isValidateInput: false,
      key: uuidv4(),
    }));
    const combinedIntervals = [...editingIntervals, ...missingIntervals];
    setIsLoading(false);
    setNewServiceIntervals(combinedIntervals);
    setShowSaveButton(true);
    setShowActions(true);
  };

  const onHandleDeleteInterval = async (value, record) => {
    try {
      const response = await apiService.delete(
        `${serviceIntervalsApi}${value.id}`
      );
      message.success(response.message);
    } catch (error) {
      message.error(error.message);
    }
    onGetIntervalsByModel(value.modelId);
  };

  const onhandleUpdateIntervals = async () => {
    try {
      setIsLoading(true);
      setFormErrors({});
      const editedServiceIntervals = newServiceIntervals.filter((elem) =>
        configuredIntervalsBe?.find(({ id }) => elem.id === id)
      );
      const newdataIntervalsInUpdate = newServiceIntervals.filter(
        (elem) => !configuredIntervalsBe?.find(({ id }) => elem.id === id)
      );
      const payload = editedServiceIntervals
        .filter(
          (eachInterval) =>
            eachInterval?.labourHours !== undefined &&
            eachInterval?.labourRateId !== "" &&
            eachInterval?.sundryId !== ""
        )
        .map((eachInterval) => ({
          id: eachInterval.id,
          modelId: selectedModel.id,
          kmMonthId: eachInterval.kmMonthId,
          hour: parseFloat(eachInterval.labourHours),
          labourRateId: eachInterval.labourRateId,
          sundryId: eachInterval.sundryId,
        }));
      const putResponse = await apiService.put(serviceIntervalsApi, payload);

      const postData = newdataIntervalsInUpdate
        .filter(
          (eachInterval) =>
            eachInterval.labourHours !== undefined &&
            eachInterval.labourRateId !== "" &&
            eachInterval.sundryId !== ""
        )
        .map((eachInterval) => ({
          modelId: selectedModel.id,
          kmMonthId: eachInterval.kmMonthId,
          hour: parseFloat(eachInterval.labourHours),
          labourRateId: eachInterval.labourRateId,
          sundryId: eachInterval.sundryId,
        }));
      
      if (postData.length>0) {
         let response = await apiService.post(serviceIntervalsApi, postData);
      }
      setCanModelChange(true)
      if (configuredIntervalsBe > 0) {
        message.success("Service intervals saved successfully");
      } else {
        message.success(putResponse.message);
      }
    } catch (error) {
      console.error("Error submitting intervals:", error.message);
    }

    onGetIntervalsByModel(selectedModel.id);
  };

  const onHandleDiscardIntervalsOnSaved = () => {
    onGetIntervalsByModel(selectedModel.id);
  };

  const actionColumn = {
    title: <span className="action-title">Action</span>,
    dataIndex: "action",
    render: (_, record) =>
      showActions &&
      (record?.labourHours !== "" ||
        record?.labourRateId !== "" ||
        record?.sundryId !== "") && (
        <div style={{ textAlign: "start" }}>
          {record.status === "NS" && !record.isEditing ? (
            <div className="actions-bg">
              <ConfirmDelete
                icon={<Button shape="square" icon={<CloseOutlined />} />}
                text={`Are you sure you want to clear the selected record "${record?.interval}"?`}
                label={"Cancel"}
                onConfirm={() => onHandleRowCancel(record)}
              />
            </div>
          ) : (
            <div className="actions-bg">
              <ConfirmDelete
                icon={<Button shape="square" icon={<DeleteOutlined />} />}
                text={`Are you sure you want to delete the selected record "${record?.interval}"?`}
                label={"Delete"}
                onConfirm={() => onHandleDeleteInterval(record)}
              />
            </div>
          )}
        </div>
      ),
    width: 80,
  };

  const columns = [
    {
      title: (
        <span className="interval-title-style">Service Interval Code</span>
      ),
      className: "interval-code-title-style",
      dataIndex: "interval",
      render: (_, record) => (
        <span className="record-text" style={{ marginLeft: "12px" }}>
          {record?.interval}
        </span>
      ),
      sorter: (a, b) => {
        const regex = /\d+/;
        const matchA =
          a.interval &&
          typeof a.interval === "string" &&
          a.interval.match(regex);
        const matchB =
          b.interval &&
          typeof b.interval === "string" &&
          b.interval.match(regex);

        if (matchA && matchB) {
          const valueA = parseInt(matchA[0], 10);
          const valueB = parseInt(matchB[0], 10);
          return valueA - valueB;
        } else if (matchA) {
          return -1;
        } else if (matchB) {
          return 1;
        } else if (!matchA && !matchB) {
          if (!a.interval && !b.interval) {
            return 0;
          } else if (!a.interval) {
            return 1;
          } else if (!b.interval) {
            return -1;
          }
        }

        return 0;
      },

      width: "200px",
    },
    {
      title: "Labour Hours",
      dataIndex: "labourHours",

      render: (_, record) =>
        record.status === "S" ? (
          <span className="record-text" style={{ marginLeft: "12px" }}>
            {record.labourHours}
          </span>
        ) : (
          <div className="cell-padding-style">
            <div className="input-error-cont">
              {record.labourHours === "" && record?.isValidateInput ? (
                <span className="error-msg">*</span>
              ) : (
                <span disabled className="error-hide">
                  *
                </span>
              )}
              <Input
                value={record.labourHours}
                type="text"
                required={true}
                placeholder="Enter Labour Hours"
                onChange={(e) => handleLabourHoursChange(e, record.id)}
                onClick={() => setShowActions(true)}
              />
            </div>
              <span className="hours-errors-message">
                {record.id === formErrors.id && formErrors.value}
              </span>
            
          </div>
        ),
      sorter: commonSorter("labourHours"),
      width: "300px",
    },
    {
      title: <span className="action-title">Labour Rate</span>,
      className: "interval-code-title-style",
      dataIndex: "labourRateId",
      render: (_, record) => (
        <div>
          {record.status === "S" ? (
            <span className="record-text" style={{ marginLeft: "12px" }}>
              {
                labourRates.find((item) => record.labourRateId === item.id)
                  ?.rateType
              }
            </span>
          ) : (
            <div className="input-error-cont">
              {record.labourRateId === "" && record?.isValidateInput ? (
                <span className="error-msg">*</span>
              ) : (
                <span disabled className="error-hide">
                  *
                </span>
              )}
              <Select
                {...(record.labourRateId === ""
                  ? { placeholder: "Select Labour Rate" }
                  : {
                      value: labourRates.find(
                        (item) => record.labourRateId === item.id
                      )?.rateType,
                    })}
                style={{ width: "100%" }}
                onClick={() => setShowActions(true)}
                  onChange={(value, option) => handleLabourRateChange(value, record, option)}
                  showSearch
              >
                {selectedModel &&
                  labourRates.map((option) => (
                    <Option
                      className="drop-down-style"
                      key={option.id}
                      value={option?.rateType}
                      id={option.id}
                    >
                      {option?.rateType}
                    </Option>
                  ))}
              </Select>
            </div>
          )}
        </div>
      ),

      width: "300px",
    },
    {
      title: <span className="action-title">RSA</span>,
      className: "interval-code-title-style",
      dataIndex: "sundryId",
      render: (_, record) => (
        <div>
          {record.status === "S" ? (
            <span className="record-text" style={{ marginLeft: "12px" }}>
              {
                rsaDealerPayments.find((item) => record.sundryId === item.id)
                  ?.code
              }
            </span>
          ) : (
            <div className="input-error-cont">
              {record.sundryId === "" && record?.isValidateInput ? (
                <span className="error-msg">*</span>
              ) : (
                <span className="error-hide">*</span>
              )}
                <Select
                showSearch
                {...(record.sundryId === ""
                  ? { placeholder: "Select Dealer Payment" }
                  : {
                      value: rsaDealerPayments.find(
                        (item) => record.sundryId === item.id
                      )?.code,
                    })}
                style={{ width: "100%" }}
                onClick={() => setShowActions(true)}
                onChange={(value, option) => handleRSAChange(value, record, option)}
              >
                {selectedModel &&
                  rsaDealerPayments.map((option) => (
                    <Option key={option.id} value={option?.code} id={option.id}>
                      {option?.code}
                    </Option>
                  ))}
              </Select>
            </div>
          )}
        </div>
      ),

      width: 300,
    },
    actionColumn,
  ];

  return (
    <div className="main-bg">
      <div
        style={{
          marginLeft: "12px",
          marginRight: "12px",
          marginBottom: "0px",
        }}
      >
        <ModelDetailsHeader
          onModelSelect={onSelectModel}
          verifyModelChange={verifyModelChange}
        />
        {isEmptyPageOpen ? (
          <EmptyTable />
        ) :
          // isLoading ? (
          // <div className="spinner-cont">
          //   <Spin />
          // </div>
          // ) :  
            (
          <div className="service-interval-input-cont">
            {showEditButton ? (
              <div className="import-cont">
                {!isEmptyPageOpen && !showSaveButton ? (
                  <div>
                    <span className="total-applied-intervals">Total : </span>
                    <span className="no-of-intrervals">
                      {configuredIntervalsBe.length === 1
                        ? `${configuredIntervalsBe.length} Item`
                        : `${configuredIntervalsBe.length} Items`}
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="buttons-bg">
                  {/* <ImportButton /> */}
                  <Button type="primary" onClick={onHandleEditIntervals}>
                    Edit Interval
                  </Button>
                </div>
              </div>
            ) : (
              <div className="button-cont-only-import">
                {/* <ImportButton /> */}
              </div>
            )}
            <div
              style={{ border: "1px #F1F1F2 solid" }}
              className="services-table"
            >
              <Table
                pagination={false}
                columns={columns}
                    dataSource={newServiceIntervals}
                    loading={isLoading}
        
              />
            </div>
          </div>
        )}
      </div>
      {!isEmptyPageOpen && showSaveButton && (
        <div className="save-header-bg">
          <div className="save-cancel-header">
            <div className="">
              <span className="total-applied-intervals">
                Total applied Intervals :
              </span>
              <span className="no-of-intrervals">
                {` ${configuredIntervalsBe.length} Intervals`}
              </span>
            </div>
            <div className="buttons-cont">
              {!showEditButton ? (
                <ConfirmDelete
                  icon={
                    <Button
                      style={{ borderRadius: "2px" }}
                      className="cancel-button"
                      disabled={disableCancel}
                    >
                      Cancel
                    </Button>
                  }
                  text={"Are you sure you want to clear all the changes?"}
                  label={"Clear"}
                  onConfirm={() => onHandleCancelIntervals()}
                />
              ) : (
                <ConfirmDelete
                  icon={
                    <Button
                      style={{ borderRadius: "2px" }}
                      className="cancel-button"
                    >
                      Cancel
                    </Button>
                  }
                  text={"Are you sure you want to discard all the changes?"}
                  label={"Discard"}
                  onConfirm={() => onHandleDiscardIntervalsOnSaved()}
                />
              )}
              {!showEditButton ? (
                <Button
                  className="save-button"
                  type="primary"
                  onClick={onHandleSubmitIntervals}
                  disabled={disableSave}
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="save-button"
                  type="primary"
                  onClick={onhandleUpdateIntervals}
                  disabled={disableSave}
                >
                  Update
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelServiceInterval;
