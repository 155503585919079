export const jsonFormat = (data) => {
  const groupedByKmId = {};
  const kmIdOrder = [];

  data?.forEach(item => {
    item.kmsDetails.forEach(kmDetail => {
      if (!groupedByKmId[kmDetail.kmId]) {
        groupedByKmId[kmDetail.kmId] = {
          kmId: kmDetail.kmId,
          labourCost: 0,
          subTotal: 0,
          grandTotal: 0,
          tax: 0,
          totalIncGST: 0,
          totalExcGST: 0,
          rebate: 0,
          rsa: 0,
          serviceMarketingProgram3Y45K: 0,
          serviceMarketingProgram4Y60K: 0,
          serviceMarketingProgram5Y75K: 0,
          parts: [],
          sundries: []
        };
        kmIdOrder.push(kmDetail.kmId); // Track the order of kmId
      }

      switch (item.key) {
        case 'labour':
          groupedByKmId[kmDetail.kmId].labourCost = kmDetail.price;
          break;
        case 'subTotal':
          groupedByKmId[kmDetail.kmId].subTotal = kmDetail.price;
          break;
        case 'tax':
          groupedByKmId[kmDetail.kmId].tax = kmDetail.price;
          break;
        case 'grandTotal':
          groupedByKmId[kmDetail.kmId].grandTotal = kmDetail.price;
          break;
        case 'totalIncGST':
          groupedByKmId[kmDetail.kmId].totalIncGST = kmDetail.price;
          break;
        case 'totalExcGST':
          groupedByKmId[kmDetail.kmId].totalExcGST = kmDetail.price;
          break;
        case 'rebate':
          groupedByKmId[kmDetail.kmId].rebate = kmDetail.price;
          break;
        case 'rsa':
          groupedByKmId[kmDetail.kmId].rsa = kmDetail.price;
          break;
        case 'smp3':
          groupedByKmId[kmDetail.kmId].serviceMarketingProgram3Y45K = kmDetail.price;
          break;
        case 'smp4':
          groupedByKmId[kmDetail.kmId].serviceMarketingProgram4Y60K = kmDetail.price;
          break;
        case 'smp5':
          groupedByKmId[kmDetail.kmId].serviceMarketingProgram5Y75K = kmDetail.price;
          break;
        default:
          if (item.partNumber) {
            const existingPart = groupedByKmId[kmDetail.kmId].parts.find(part => part.partNumber === item.partNumber && part.itemTechConfDetailId === item.itemTechConfDetailId);
            if (existingPart) {
              existingPart.qty = item.qty;
              existingPart.price += parseFloat(kmDetail.price);
            } else {
              groupedByKmId[kmDetail.kmId].parts.push({
                id: item.id,
                partNumber: item.partNumber,
                itemTechConfDetailId:item.itemTechConfDetailId,
                name: item.name,
                qty: item.qty,
                price: parseFloat(kmDetail.price),
                isChargesApplicable: kmDetail.isActive,
                isEditablePrice: kmDetail.isActive
              });
            }
          } else if (item.description) {
            groupedByKmId[kmDetail.kmId].sundries.push({
              id: item.id,
              code: item.code,
              name: item.description,
              value: parseFloat(item.value)
            });
          }
          break;
      }
    });
  });

  const kmsDetails = kmIdOrder.map(kmId => groupedByKmId[kmId]);

  const details = {
    id: 0,
    modelId: 0,
    kmsDetails
  };

  return details;
};
