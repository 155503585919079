import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider} from "antd";
import "./Assets/styles/GlobalStyles.css";
import "../src/Components/Home/home.css"



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <ConfigProvider>
    <BrowserRouter>
        <App />
      </BrowserRouter>
      </ConfigProvider>
  </RecoilRoot>
);

reportWebVitals();
