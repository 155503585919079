import React, { useEffect, useRef, useState } from "react";
import "./labourRates.css";
import { Input, Form, message, Select, Row, Col, Tooltip } from "antd";
import { commonSorter } from "../../../CommonComponents/SortingLogic";
import EmptyMasterData from "../EmptyMasterData/EmptyMasterData";
import CommonTable from "../../../CommonComponents/Table/CommonTable";
import { listLabourRate, fuelTypes } from "../../../Services/Endpoints";
import apiService from "../../../Services/commonServices";
import ModalMaster from "../../../CommonComponents/modals/Modal-Master/ModalMaster";
import { Option } from "antd/es/mentions";
import {
  handleDescriptionInputChange,
  onHandleValueInputChange,
} from "../commonInputFunctions";

const LabourRate = () => {
  const hiddenSubmitButton = useRef(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [allLabourRateItems, setAllLabourRateItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editLabourRateItem, setEditLabourRateItem] = useState({});
  const [ismodalAddState, setIsModalAddState] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [orginalLabourRateItems, setOriginalLabourRateItems] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [listFuelTypes, setListFuelTypes] = useState([]);

  const [formData, setFormData] = useState({
    rateType: "",
    value: "",
    description: "",
    engineTypeDetails: "",
  });

  useEffect(() => {
    setIsLoading(true);
    getAllLabourRateItems();
    getFuelTypes();
  }, []);

  const makeLabourRateItemEmpty = () => {
    setFormData({
      rateType: "",
      value: "",
      description: "",
      engineTypeDetails: "",
    });
  };

  const handleCancel = () => {
    setFormErrors(false);
    setIsModalOpen(false);
    setIsModalAddState(true);
    makeLabourRateItemEmpty();
    setDisableSave(true);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleFinish();
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData?.engineTypeDetails) {
      errors.engineTypeDetails = "Please select an option.";
    }

    if (!formData?.rateType) {
      errors.rateType = "This field is required.";
    } else {
      const rateTypeExists = allLabourRateItems?.some(
        (item) =>
          (item?.rateType.trim()?.toLowerCase() ===
            formData.rateType.trim()?.toLowerCase() &&
          item.engineTypeDetails.id === formData.engineTypeDetails.id )&&
          (!editLabourRateItem || item?.id !== editLabourRateItem?.id)
      );
      if (rateTypeExists) {
        errors.rateType =
          "Duplicate rate type. Please enter a unique rate type.";
      }
    }

    if (!formData?.value) {
      errors.value = "This field is required.";
    } else if (Number(formData.value) <= 0) {
      errors.value = "Value must be greater than zero.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onHandleNameInputChange = (e) => {
    setDisableSave(false);
    const { value } = e.target;

    if (value.trim().length === 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        rateType: "",
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        rateType: "This field is required.",
      }));
      return;
    }

    if (value.length > 50) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        rateType: "Max 50 characters.",
      }));
      return;
    }

    const rateTypeExists = allLabourRateItems?.some(
      (item) =>
        item?.rateType.trim()?.toLowerCase() === value.trim()?.toLowerCase() &&
        item.engineTypeDetails.id === formData.engineTypeDetails.id &&
        (!editLabourRateItem || item?.id !== editLabourRateItem?.id)
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      rateType: value,
    }));

    if (rateTypeExists) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        rateType: "Duplicate rate type. Please enter a unique rate type.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        rateType: "",
      }));
    }
  };

  const onHandleFuelTypeInputChange = (value) => {
    const fuelTypeObject = listFuelTypes.find(
      (eachObject) => eachObject?.id === value
    );

    const rateTypeExists = allLabourRateItems?.some(
      (item) =>
        (item?.rateType.trim()?.toLowerCase()  === formData.rateType.trim()?.toLowerCase() &&
        item.engineTypeDetails.id === fuelTypeObject?.id) &&
        (!editLabourRateItem || item.id !== editLabourRateItem.id)
    );
    if (rateTypeExists) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        rateType: "Duplicate rate type. Please enter a unique rate type.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        rateType: "",
      }));
    }

    setDisableSave(false);
    setFormData((prevState) => ({
      ...prevState,
      engineTypeDetails: fuelTypeObject,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      engineTypeDetails: "",
    }));
  };

  const onHandlevalueBlur = () => {
    const trimmedValue = formData.value.trim();

    if (trimmedValue === "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        value: "",
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        value: "This field is required.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        value: "",
      }));
    }
  };

  const onHandleDescriptionBlur = () => {
    setFormErrors((prevErrors) => ({
      showRemainingChar: false,
    }));
  };

  const onHandleValueChange = (e) => {
    setDisableSave(false);
    onHandleValueInputChange(
      e,
      formData,
      setFormData,
      formErrors,
      setFormErrors
    );
  };

  const onHandleDescriptionChange = (e) => {
    setDisableSave(false);
    handleDescriptionInputChange(
      e,
      setFormData,
      setFormErrors,
      setDisableSave,
      formErrors
    );
  };

  const onHandleSearch = (e) => {
    const { value } = e.target;
    const filteredItems = orginalLabourRateItems.filter(
      (item) =>
        (item.rateType &&
          item.rateType?.toLowerCase().includes(value?.toLowerCase())) ||
        (item.value && item.value.includes(value)) ||
        (item.description &&
          item.description?.toLowerCase().includes(value?.toLowerCase())) ||
        (item.engineTypeDetails &&
          item.engineTypeDetails.name &&
          item.engineTypeDetails.name
            ?.toLowerCase()
            .includes(value?.toLowerCase()))
    );
    setAllLabourRateItems(filteredItems);
  };

  const getAllLabourRateItems = async () => {
    try {
      const responseData = await apiService.get(listLabourRate);
      setAllLabourRateItems(responseData.data);
      setOriginalLabourRateItems(responseData.data);
      setIsEmpty(responseData.data.length === 0);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const getFuelTypes = async () => {
    try {
      const responseData = await apiService.get(fuelTypes);
      setListFuelTypes(responseData.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const onHandleAddLabourRateItem = async (postNewLabourRateItem) => {
    try {
      const response = await apiService.post(
        listLabourRate,
        postNewLabourRateItem
      );
      message.success(response.message);
    } catch (error) {
      console.error("Error:", error.message);
      message.error(error.message);
    }
    getAllLabourRateItems();
  };

  const onHandleEditLabourRateItem = (record) => {
    setIsModalOpen(true);
    setIsModalAddState(false);
    setDisableSave(true);
    const editItem = allLabourRateItems.find(
      (eachObject) => eachObject.id === record?.id
    );
    setEditLabourRateItem(editItem);
    setFormData({
      rateType: editItem.rateType,
      value: editItem.value,
      description: editItem.description,
      engineTypeDetails: editItem.engineTypeDetails,
    });
  };

  const onHandleUpdateLabourRateItem = async (updatedLabourRateItem) => {
    try {
      const response = await apiService.put(
        `${listLabourRate}${updatedLabourRateItem.id}`,
        updatedLabourRateItem
      );
      message.success(response.message);
    } catch (error) {
      console.error("Error:", error.message);
      message.error(error.message);
    }
    getAllLabourRateItems();
  };

  const handleFinish = () => {
    setIsModalOpen(false);
    setIsLoading(true);
    let updatedValue = parseFloat(formData.value);
    if (Number.isInteger(updatedValue)) {
      updatedValue = updatedValue.toFixed(2);
    }
    if (
      !Number.isInteger(updatedValue) &&
      updatedValue.toString().split(".")[1]?.length === 1
    ) {
      updatedValue = updatedValue.toFixed(2);
    }
    if (ismodalAddState) {
      const postNewLabourRateItem = {
        ...formData,
        value: updatedValue,
        engineTypeId: formData.engineTypeDetails.id,
        rateType: formData.rateType.trim(),
        description: formData.description.trim(),
      };

      onHandleAddLabourRateItem(postNewLabourRateItem);
    } else {
      const updatedItem = {
        ...editLabourRateItem,
        rateType: formData.rateType.trim(),
        description: formData.description.trim(),
        value: updatedValue,
        engineTypeId: formData.engineTypeDetails.id,
      };
      onHandleUpdateLabourRateItem(updatedItem);
    }
    makeLabourRateItemEmpty();
  };

  const onHandleDeleteItem = async (record) => {
    try {
      const response = await apiService.delete(`${listLabourRate}${record.id}`);
      message.success(response.message);
      getAllLabourRateItems();
    } catch (error) {
      message.warning(error.message);
    }
  };

  const handleExcelData = (json) => {};

  const columns = [
    {
      title: "Rate Type",
      dataIndex: "rateType",
      render: (_, record) => (
        <span className="record-text">{record?.rateType}</span>
      ),
      sorter: commonSorter("rateType"),
      width: 300,
    },
    {
      title: "Value ($)",
      dataIndex: "value",
      render: (_, record) => (
        <span className="record-text">{record?.value}</span>
      ),
      sorter: commonSorter("value"),
      width: 250,
    },
    {
      title: "Fuel Type",
      dataIndex: "engineType",
      render: (_, record) => (
        <span className="record-text">{record?.engineTypeDetails?.name}</span>
      ),
      sorter: (a, b) =>
        a.engineTypeDetails?.name.localeCompare(b.engineTypeDetails?.name),
      width: 300,
    },
    {
      title: "Comments",
      className: "description-cell-style",
      dataIndex: "description",
      render: (_, record) => (
        <Tooltip
          height={500}
          className="table-cell-tooltip-style"
          placement="topLeft"
          title={record?.description?.length > 60 ? record?.description : ""}
        >
          <span className="record-text">{record?.description}</span>
        </Tooltip>
      ),
      width: 656,
    },
  ];

  return (
    <>
      <div>
        {isEmpty ? (
          <EmptyMasterData
            onClickAdd={() => {
              setIsModalAddState(true);
              setIsModalOpen(true);
            }}
          />
        ) : (
          <div>
            <CommonTable
              columns={columns}
              dataSource={allLabourRateItems}
              rowKey="id"
              isLoading={isLoading}
              onClickAdd={() => {
                setIsModalAddState(true);
                setIsModalOpen(true);
              }}
              onChange={onHandleSearch}
              handleExcelData={handleExcelData}
              onEdit={onHandleEditLabourRateItem}
              onDelete={onHandleDeleteItem}
              resourceName={"Labour Rate"}
            />
          </div>
        )}

        <div className="system-modal-parent">
          <ModalMaster
            openModal={isModalOpen}
            closeModal={handleCancel}
            label={ismodalAddState ? "Add Labour Rate" : "Edit Labour Rate"}
            onClickSubmit={handleSubmit}
            width={650}
            isDisableOkButton={!ismodalAddState && disableSave}
            buttonText={ismodalAddState ? "Add" : "Save"}
            content={
              <Form
                name="wrap"
                labelCol={{}}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                style={{ maxWidth: 650 }}
                onFinish={handleSubmit}
              >
                <div className="form-inputs-parent">
                  <Row gutter={16}>
                    <Col>
                      <Form.Item
                        label="Rate Type"
                        required
                        validateStatus={formErrors.rateType ? "error" : ""}
                        help={formErrors.rateType}
                        style={{ width: "240px" }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Enter Rate Type"
                          value={formData.rateType}
                          onChange={onHandleNameInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label="Value"
                        required
                        validateStatus={formErrors.value ? "error" : ""}
                        help={formErrors.value}
                        style={{ width: "170px" }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <div className="sundry-parent-value-input">
                          <Input
                            placeholder="Enter Value"
                            value={formData.value}
                            onChange={onHandleValueChange}
                            addonBefore="$"
                            className="value-input"
                            onBlur={onHandlevalueBlur}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label="Fuel Type "
                        required
                        validateStatus={
                          formErrors.engineTypeDetails ? "error" : ""
                        }
                        help={formErrors.engineTypeDetails}
                        style={{ width: "150px" }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select
                          placeholder="Select Fuel Type"
                          onChange={onHandleFuelTypeInputChange}
                          className="km-month-select"
                          value={formData.engineTypeDetails?.name || undefined}
                        >
                          {listFuelTypes.map((eachObject) => (
                            <Option key={eachObject.id} value={eachObject.id}>
                              {eachObject.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Comments"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        validateStatus={formErrors.description ? "error" : ""}
                        help={formErrors.description}
                      >
                        <Input.TextArea
                          placeholder="Enter Comments"
                          value={formData.description}
                          onChange={onHandleDescriptionChange}
                        />
                        {formErrors.showRemainingChar && (
                          <span className="remaining-characters-style">{`Remaining ${formData.remainingCharlength} characters`}</span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <button
                  ref={hiddenSubmitButton}
                  type="submit"
                  style={{ display: "none" }}
                />
              </Form>
            }
          />
        </div>
      </div>
    </>
  );
};

export default LabourRate;
