import React, { useState, useEffect } from "react";
import "./profileHeader.css";
import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Services/authService";
import apiService from "../../Services/commonServices";
import { profile } from "../../Services/Endpoints";

const ProfileHeader = ({ handleView }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [shortCutName, setShortCutName] = useState("");
  const [randomColor, setRandomColor] = useState("#00bfeb");


  const navigate = useNavigate();

  useEffect(() => {
    userData();
  }, []);

  const getRandomColor = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  };

  const userData = async () => {
    try {
      const userDetails = await apiService.get(profile);
      console.log(userDetails)
      const shortCut = `${userDetails.userDetails?.firstName?.charAt(0).toUpperCase()}${userDetails.userDetails?.lastName?.charAt(0).toUpperCase()}`
      setShortCutName(shortCut)
      setUserDetails(userDetails.userDetails);
   
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="javascript:void(0)" onClick={handleLogout}>
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className="profile-header-bg">
      <div
        className="profile-menu-bg"
      >
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          open={isDropdownOpen}
          onVisibleChange={toggleDropdown}
        >
          <div className="userName-flex" onClick={(e) => e.preventDefault()}>
              <div className="Avatar-style" style={{backgroundColor:randomColor}}>
             {shortCutName !== "" && <span className="name-short-cut">
              {shortCutName}
              </span>}
              </div>
            <div style={{ textAlign: "start" }}>
              {(userDetails.length===0)?
              <h2 className="profile-name">Unknown User</h2>:
            <h2 className="profile-name">{`${userDetails.firstName || ''} ${userDetails.lastName || ''}`}</h2>}
              {/* <p className="designation">Admin</p> */}
            </div>
            <span style={{ color: "#0C0D0E", width: "9px", marginLeft: "8px" }}>
              <CaretDownOutlined />
            </span>
          </div>
        </Dropdown>
      </div>
    </header>
  );
};

export default ProfileHeader;
