import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import {
  commonSorter,
  alphanumericSorter,
} from "../../../CommonComponents/SortingLogic";
import EmptyMasterData from "../EmptyMasterData/EmptyMasterData";
import CommonTable from "../../../CommonComponents/Table/CommonTable";
import { listParts } from "../../../Services/Endpoints";
import apiService from "../../../Services/commonServices";
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  message,
} from "antd";
import {
  getMaxCharsRule,
  requiredRule,
  onHandleNumericValidation,
  onHandleMaxCharacterValidation,
  zeroValueRule,
} from "../../../CommonComponents/form/Validation";
import "./parts.css";

const Part = () => {
  const hiddenSubmitButton = useRef(null);
  const [isEmpty, setisEmpty] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState(200);
  const [remainingCharLength, setRemainingCharLength] = useState(200);
  const [editItemCode, setEditItemCode] = useState("");
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const responseData = await apiService.get(listParts);
      if (responseData && Array.isArray(responseData.data)) {
        setAllItems(responseData?.data);
        setFilteredItems(responseData?.data);
        setisEmpty(responseData?.data.length === 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (event) => {
    const searchText = event.target.value?.toLowerCase();
    const filteredData = allItems.filter((item) => {
      const itemCode = item.itemCode ? item.itemCode?.toLowerCase() : "";
      const itemDescription = item.itemDescription
        ? item.itemDescription?.toLowerCase()
        : "";
      const comments = item.comments ? item.comments?.toLowerCase() : "";
      const itemValue = item.itemValue
        ? parseFloat(item.itemValue).toFixed(2)?.toLowerCase()
        : "";

      return (
        itemCode.includes(searchText) ||
        itemDescription.includes(searchText) ||
        comments.includes(searchText) ||
        itemValue.includes(searchText)
      );
    });
    setFilteredItems(filteredData);
  };

  const columns = [
    {
      title: "Part Number",
      dataIndex: "itemCode",
      render: (_, record) => (
        <span className="record-text" style={{}}>
          {record?.itemCode}
        </span>
      ),
      sorter: alphanumericSorter("itemCode"),
      width: 170,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      render: (_, record) => (
        <span className="record-text" style={{}}>
          {record?.itemDescription}
        </span>
      ),
      sorter: alphanumericSorter("itemDescription"),
      width: 234,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      render: (_, record) => (
        <Tooltip
          title={record?.comments?.length > 70 ? record?.comments : ""}
          placement="topLeft"
        >
          <span className="record-text">{record?.comments}</span>
        </Tooltip>
      ),
      onCell: () => ({
        style: {
          maxWidth: 50,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
        },
      }),
    },
    {
      title: "Value($)",
      dataIndex: "itemValue",
      render: (_, record) => (
        <span className="record-text" style={{}}>
          {record?.itemValue}
        </span>
      ),
      sorter: commonSorter("itemValue"),
      width: 130,
    },
  ];

  const handleCommentsChange = (e) => {
    const { value } = e.target;
    const remaining = maxCharacters - value.length;
    setRemainingCharLength(remaining);
    if (remaining >= 0) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  };

  const openModal = () => {
    setIsEdit(false);
    setCurrentRecord(null);
    form.resetFields();
    setShowModal(true);
    setShowMessage(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          if(values.itemValue !== 0) {
            const requestData = {
              itemDescription: values.itemDescription,
              itemCode: values.itemCode,
              itemValue: values.itemValue,
              comments: values.comments,
            };
            setShowModal(false);
            if (isEdit) {
              setIsLoading(true);
              const url = `${listParts}/${currentRecord.id}`;
              const responseData = await apiService.put(url, requestData);
              message.success("Part updated successfully");
              setIsLoading(false);
            } else {
              setIsLoading(true);
              const responseData = await apiService.post(listParts, requestData);
              message.success("Part added successfully");
              setIsLoading(false);
            }
            fetchData();
  
            setIsFormChanged(false);
          }
          else{
            form.setFields([
              {
                name: "itemValue",
                errors: ["Value must be greater than zero."],
              },
            ]);
          }
        
        } catch (error) {
          console.error("Error saving part:", error.message);
        }
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleDelete = async (record) => {
    try {
      const url = `${listParts}/${record.id}`;
      const response = await apiService.delete(url);
      message.success(response.message);
      fetchData();
    } catch (error) {
      message.warning(error.message);
    }
  };

  const handleEdit = (record) => {
    form.resetFields();
    setShowMessage(false);
    setIsEdit(true);
    setCurrentRecord(record);
    setEditItemCode(record.itemCode);
    form.setFieldsValue({
      itemCode: record.itemCode,
      itemDescription: record.itemDescription,
      itemValue: record.itemValue,
      comments: record.comments,
    });
    setShowModal(true);
  };

  const handleSubmit = () => {
    handleOk();
    setShowMessage(false);
  };

  const handleCancel = () => {
    setShowModal(false);
    setShowMessage(false);
    setIsFormChanged(false);
    form.resetFields();
  };

  const validateItemCode = async (rule, value) => {
    if (value) {
      const trimmedValue = value.trim();
      if (isEdit && editItemCode === trimmedValue) {
        return;
      }
      const exists = allItems.some(
        (item) =>
          item.itemCode.trim()?.toLowerCase() === trimmedValue?.toLowerCase() && item.id !== form.getFieldValue("id")
      );

      if (exists) {
        throw new Error("Duplicate part number. Please enter a unique code.");
      }
    }
  };
  return (
    <>
      {isEmpty ? (
        <EmptyMasterData onClickAdd={openModal} />
      ) : (
        <CommonTable
          columns={columns}
          dataSource={filteredItems}
          rowKey="id"
          onClickAdd={openModal}
          isLoading={isLoading}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onChange={handleSearch}
          resourceName={"Part"}
        />
      )}
      <Modal
        title={isEdit ? "Edit Part" : "Add Part"}
        open={showModal}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText={isEdit ? "Save" : "Add"}
        okButtonProps={{ disabled: isEdit && !isFormChanged }}
        width={750}
        centered
        className="custom-modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={() => setIsFormChanged(form.isFieldsTouched())}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col>
              <Form.Item
                name="itemCode"
                label="Part Number"
                rules={[
                  requiredRule,
                  getMaxCharsRule("Part Number", 20),
                  { validator: validateItemCode },
                ]}
                style={{ width: "200px" }}
              >
                <Input
                  placeholder="Enter Part Number"
                  onBlur={() => {
                    form.validateFields(["itemCode"]);
                  }}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="itemDescription"
                label="Description"
                rules={[requiredRule, getMaxCharsRule("Part Number", 100)]}
                style={{ width: "270px" }}
              >
                <Input placeholder="Enter Description" autoComplete="off" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Value"
                name="itemValue"
                rules={[
                  requiredRule,
                  getMaxCharsRule("Part Number", 15),
                  zeroValueRule,
                ]}
                style={{ width: "200px" }}
              >
                <Input
                  placeholder="Enter Value"
                  addonBefore="$"
                  className="part-value"
                  onBlur={() => {
                    form.validateFields(["itemValue"]);
                  }}
                  onKeyPress={(e) =>
                    onHandleNumericValidation(e, form, "itemValue")
                  }
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="comments"
                label="Comments"
                style={{ width: "auto" }}
              >
                <TextArea
                  placeholder="Enter Comments"
                  onChange={(e) => {
                    handleCommentsChange(e);
                    onHandleMaxCharacterValidation(
                      e,
                      form,
                      "comments",
                      maxCharacters
                    );
                  }}
                  autoComplete="off"
                />
              </Form.Item>
              {showMessage && (
                <div
                  className="remaining-characters-style"
                  style={{ position: "relative", bottom: "8px" }}
                >
                  Remaining {remainingCharLength} Characters
                </div>
              )}
            </Col>
          </Row>
          <button
            ref={hiddenSubmitButton}
            type="submit"
            style={{ display: "none" }}
          />
        </Form>
      </Modal>
    </>
  );
};

export default Part;
