import React from "react";
import SideMenu from "../../Layouts/SideMenu/SideMenu";
import { Layout } from "antd";
import PageHeader from "../../Layouts/PageHeader/PageHeader";
import ProfileHeader from "../../Layouts/ProfileHeader/ProfileHeader";
import { useRecoilState } from "recoil";
import { sideNavCollapsedState } from "../../Recoil/recoilState";

const { Content } = Layout;

const MainPage = ({ContentComponent}) => {
  const [collapsed, setCollapsed] = useRecoilState(sideNavCollapsedState);

  return (
    <Layout style={{ height: "100vh", display: "flex", flexDirection: "row" }}>
      <SideMenu />
      <Layout className="main-layout" style={{ width: !collapsed && "768px" }}>
        <div>
          <ProfileHeader  />
          <PageHeader />
        </div>
        <Content style={{ height: "80vh", overflow: "auto" }}>
          {ContentComponent}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainPage;
