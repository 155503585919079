import React, { useEffect, useRef, useState } from "react";
import "./sundry.css";
import { Input, Form, message, Row, Col, Tooltip } from "antd";
import { commonSorter } from "../../../CommonComponents/SortingLogic";
import EmptyMasterData from "../EmptyMasterData/EmptyMasterData";
import CommonTable from "../../../CommonComponents/Table/CommonTable";
import { listRSAdealerPayments } from "../../../Services/Endpoints";
import apiService from "../../../Services/commonServices";
import ModalMaster from "../../../CommonComponents/modals/Modal-Master/ModalMaster";
import {
  handleDescriptionInputChange,
  onHandleValueInputChange,
} from "../commonInputFunctions";

const Sundry = () => {
  const hiddenSubmitButton = useRef(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [allSundryItems, setAllSundryItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editSundryItem, setEditSundryItem] = useState({});
  const [ismodalAddState, setIsModalAddState] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [orginalSundryItems, setOriginalSundryItems] = useState([]);
  const [disableSave, setDisableSave] = useState(true);

  const [formData, setFormData] = useState({
    code: "",
    value: "",
    description: "",
  });

  useEffect(() => {
    setIsLoading(true);
    getAllSundryItems();
  }, []);

  const makeSundryItemEmpty = () => {
    setFormData({
      code: "",
      value: "",
      description: "",
    });
  };

  const handleCancel = () => {
    setFormErrors(false);
    setIsModalOpen(false);
    setIsModalAddState(true);
    makeSundryItemEmpty();
    setDisableSave(true);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleFinish();
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData?.code) {
      errors.code = "This field is required.";
    }

    if (!formData?.value) {
      errors.value = "This field is required.";
    } 

    const codeExists = allSundryItems?.some(
      (item) =>
        item?.code.trim()?.toLowerCase() ===
          formData?.code.trim()?.toLowerCase() && item.id !== editSundryItem.id
    );
    if (codeExists) {
      errors.code = "Duplicate sundry code. Please enter a unique sundry code";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onHandleNameInputChange = (e) => {
    setDisableSave(false);
    const { value } = e.target;

    if (value.trim().length === 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        code: "",
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        code: "This field is required.",
      }));
      return;
    }

    if (value.length > 50) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        code: "Max 50 characters.",
      }));
      return;
    }

    const codeExists = allSundryItems?.some(
      (item) =>
        item?.code.trim()?.toLowerCase() === value.trim()?.toLowerCase() &&
        (!editSundryItem || item.id !== editSundryItem.id)
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      code: value,
    }));

    if (codeExists) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        code: "Duplicate sundry code. Please enter a unique sundry code.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        code: "",
      }));
    }
  };

  const onHandleValueChange = (e) => {
    setDisableSave(false);
    onHandleValueInputChange(
      e,
      formData,
      setFormData,
      formErrors,
      setFormErrors
    );
  };

  const onHandleDescriptionChange = (e) => {
    setDisableSave(false);
    handleDescriptionInputChange(
      e,
      setFormData,
      setFormErrors,
      setDisableSave,
      formErrors
    );
  };

  const onHandleSearch = (e) => {
    const { value } = e.target || "";
    const filteredItems = orginalSundryItems?.filter((item) => {
      return (
        !value ||
        (item?.code && item.code?.toLowerCase().includes(value?.toLowerCase())) ||
        (item?.value &&
          item.value?.toLowerCase().includes(value?.toLowerCase())) ||
        (item?.description &&
          item.description?.toLowerCase().includes(value?.toLowerCase()))
      );
    });
    setAllSundryItems(filteredItems);
  };

  const getAllSundryItems = async () => {
    try {
      const responseData = await apiService.get(listRSAdealerPayments);
      setAllSundryItems(responseData.data);
      setOriginalSundryItems(responseData.data);
      setIsEmpty(responseData.data.length === 0);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const onHandleAddSundryItem = async (postNewSundryItem) => {
    try {
      const response = await apiService.post(
        listRSAdealerPayments,
        postNewSundryItem
      );
      message.success(response.message);
    } catch (error) {
      console.error("Error:", error.message);
      message.error(error.message);
    }
    getAllSundryItems();
  };

  const onHandleEditSundryItem = (record) => {
    setIsModalOpen(true);
    setIsModalAddState(false);
    setDisableSave(true);
    const editItem = allSundryItems.find(
      (eachObject) => eachObject.id === record?.id
    );
    setEditSundryItem(editItem);
    setFormData({
      code: editItem.code,
      value: editItem.value,
      description: editItem.description,
    });
  };

  const onHandlevalueBlur = () => {
    const trimmedValue = formData?.value?.trim();

    if (trimmedValue === "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        value: "",
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        value: "This field is required.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        value: "",
      }));
    }
  };

  const onHandleDescriptionBlur = () => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      showRemainingChar: false,
    }));
  };

  const onHandleUpdateSundryItem = async (updatedSundryItem) => {
    try {
      const response = await apiService.put(
        `${listRSAdealerPayments}${editSundryItem.id}`,
        updatedSundryItem
      );
      message.success(response.message);
    } catch (error) {
      console.error("Error:", error.message);
      message.error(error.message);
    }
    getAllSundryItems();
  };

  const handleFinish = () => {
    setIsModalOpen(false);
    setIsLoading(true);
    setFormErrors({});
    let updatedValue = parseFloat(formData.value);
    if (Number.isInteger(updatedValue)) {
      updatedValue = updatedValue.toFixed(2);
    }
    if (
      !Number.isInteger(updatedValue) &&
      updatedValue.toString().split(".")[1]?.length === 1
    ) {
      updatedValue = updatedValue.toFixed(2);
    }
    if (ismodalAddState) {
      const postNewSundryItem = {
        ...formData,
        value: updatedValue,
        code: formData.code.trim(),
        description: formData.description.trim(),
      };

      onHandleAddSundryItem(postNewSundryItem);
    } else {
      const updatedItem = {
        ...editSundryItem,
        code: formData.code.trim(),
        description: formData.description.trim(),
        value: updatedValue,
      };
      onHandleUpdateSundryItem(updatedItem);
    }
    makeSundryItemEmpty();
  };

  const onHandleDeleteItem = async (record) => {
    try {
      const response = await apiService.delete(
        `${listRSAdealerPayments}${record.id}`
      );
      message.success(response.message);
      getAllSundryItems();
    } catch (error) {
      message.warning(error.message);
    }
  };

  const handleExcelData = (json) => {};

  const columns = [
    {
      title: "Sundry Code",
      dataIndex: "code",
      render: (_, record) => (
        <span className="record-text">{record?.code}</span>
      ),
      sorter: commonSorter("code"),
      width: 300,
    },
    {
      title: "Value ($)",
      dataIndex: "value",
      render: (_, record) => (
        <span className="record-text">{record?.value}</span>
      ),
      sorter: commonSorter("value"),
      width: 250,
    },
    {
      title: "Comments",
      className: "description-cell-style",
      dataIndex: "description",
      render: (_, record) => (
        <Tooltip
          height={500}
          className="table-cell-tooltip-style"
          placement="topLeft"
          title={record?.description?.length > 70 ? record?.description : ""}
        >
          <span className="record-text">{record?.description}</span>
        </Tooltip>
      ),
      width: 656,
    },
  ];

  return (
    <>
      <div>
        {isEmpty ? (
          <EmptyMasterData
            onClickAdd={() => {
              setIsModalAddState(true);
              setIsModalOpen(true);
            }}
          />
        ) : (
          <div>
            <CommonTable
              columns={columns}
              dataSource={allSundryItems}
              rowKey="id"
              isLoading={isLoading}
              onClickAdd={() => {
                setIsModalAddState(true);
                setIsModalOpen(true);
              }}
              onChange={onHandleSearch}
              handleExcelData={handleExcelData}
              onEdit={onHandleEditSundryItem}
              onDelete={onHandleDeleteItem}
              resourceName={"Sundry"}
            />
          </div>
        )}
        <div className="system-modal-parent">
          <ModalMaster
            openModal={isModalOpen}
            closeModal={handleCancel}
            label={ismodalAddState ? "Add Sundry" : "Edit Sundry"}
            onClickSubmit={handleSubmit}
            width={566}
            isDisableOkButton={!ismodalAddState && disableSave}
            buttonText={ismodalAddState ? "Add" : "Save"}
            content={
              <Form
                name="wrap"
                labelCol={{}}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
              >
                <div className="form-inputs-parent">
                  <Row gutter={16}>
                    <Col>
                      <Form.Item
                        label="Sundry Code"
                        required
                        validateStatus={formErrors.code ? "error" : ""}
                        help={formErrors.code}
                        style={{ width: "322px" }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Enter Sundry Code"
                          value={formData.code}
                          onChange={onHandleNameInputChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label="Value"
                        required
                        validateStatus={formErrors.value ? "error" : ""}
                        help={formErrors.value}
                        style={{ width: "170px" }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <div className="sundry-parent-value-input">
                          <Input
                            placeholder="Enter Value"
                            value={formData.value}
                            onChange={onHandleValueChange}
                            addonBefore="$"
                            className="value-input"
                            onBlur={onHandlevalueBlur}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Comments"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        validateStatus={formErrors.description ? "error" : ""}
                        help={formErrors.description}
                      >
                        <Input.TextArea
                          placeholder="Enter Comments"
                          value={formData.description}
                          onChange={onHandleDescriptionChange}
                        />
                        {formErrors.showRemainingChar && (
                          <span className="remaining-characters-style">{`Remaining ${formData.remainingCharlength} characters`}</span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <button
                  ref={hiddenSubmitButton}
                  type="submit"
                  style={{ display: "none" }}
                />
              </Form>
            }
          />
        </div>
      </div>
    </>
  );
};

export default Sundry;
