import React from "react";
import EmptyIconSvg from "../../Assets/Icons/Empty_Svg_Icon.svg";

const EmptyTable = () => (
  <div
    style={{
      minHeight: "56vh",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "#FFFFFF",
      marginTop: "12px",
      borderRadius: "4px",
      boxShadow: "0px 6px 16px -4px #D2D5DA99",
    }}
  >
    <img src={EmptyIconSvg} alt="empty" />
    <span
      style={{
        lineHeight: "22px",
        fontWeight: 500,
        fontSize: "16px",
        color: "#21272A",
        fontFamily: "Hyundai Sans Text Office Regular",
        marginTop: "24px",
      }}
    >
      Select model to configure or show Technical Configuration
    </span>
  </div>
);
export default EmptyTable;
