import { Navigate } from 'react-router-dom'
import MainPage from '../Pages/MainPage/MainPage'
import { auth } from '../Services/authService';
import React, { useEffect , useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin} from "antd";

const ProtectedRoute = ({ Component }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      auth.login(token); 
      queryParams.delete("token");
      window.history.replaceState({}, "", `${location.pathname}`);
    }

    const authResult = auth.check();
    setIsAuthenticated(authResult); 
  }, []); 

 
 if (isAuthenticated === null) {
  return <Spin />; 
}

return isAuthenticated ? (
  <MainPage ContentComponent={Component} />
) : (
  <Navigate to="/login" />
);

};

export default ProtectedRoute;