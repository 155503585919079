import React, { lazy, useCallback, useEffect, useState } from "react";
import {
	Button,
	Checkbox,
	Form,
	Input,
	Modal,
	Row,
	Switch,
	Table,
	Tooltip,
	message,
} from "antd";
import ModelDetailsHeader from "../../Layouts/Model-Details-Header/ModelDetails";
import EmptyModelTableContent from "../../CommonComponents/Empty-Pages/Model-Selection-Empty/ModelSelectionEmpty";
import SundryHeader from "./Sundry-Header/SundryHeader";
import apiService from "../../Services/commonServices.js";
import {
	getGspConfigurations,
	gspConfiguration,
	syncTechnicalConfiguration
} from "../../Services/Endpoints.js";
import "./gsp-configuration.css";
import {
	CloseOutlined,
	ExclamationCircleOutlined,
	MinusOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import ConfirmDelete from "../../CommonComponents/modals/ConfirmModal.jsx";
import { transformData } from "./jsonHelper.js";
import { commonSorter } from "../../CommonComponents/SortingLogic.js";
import {
	RedirectModelServiceDiv,
	RedirectTechDiv,
} from "./Redirect-Comp/Redirect.jsx";
import { jsonFormat } from "./jsonReverse.js";
import {
	calculate3YProgramme,
	calculate4YProgramme,
	calculate5YProgramme,
	calculateExcGST,
	calculatePartsAndLubricants,
	calculateRebate,
	calculateSubtotalRows,
	calculateTax,
	grandTotalCalulate,
} from "./gspCalculation.js";
import { Navigate, useNavigate } from "react-router-dom";
import confirmPopup from "../../CommonComponents/Confirmation-Popup/Confirm.jsx";
import SyncModal from "../../CommonComponents/modals/GSP-Sync-Modal/GspSyncModal.jsx";
const LazyModelDetailsHeader = lazy(() =>
	import("../../Layouts/Model-Details-Header/ModelDetails")
);

const GspConfiguration = () => {
	const [isModelSelected, setIsModelSelected] = useState(false);
	const [selectedModel, setSelectedModel] = useState({});
	const [partsData, setPartsData] = useState([]);
	const [fetchedPartsdata, setFetchedPartsData] = useState({});
	const [kmTitleData, setKmTitleData] = useState([]);
	const [staticRowsData, setStaticRowsData] = useState([]);
	const [totalIntervals, setTotalIntervals] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false);
	const [toggleExpand, setToggleExpand] = useState(false);
	const [sundryRowSpanValue, setSundryRowSpanValue] = useState(0);
	const [canChangeModel, setCanChangeModel] = useState(true);
	const [noTechConfig, setNoTechConfig] = useState(false);
	const [structuredOriginalData, setStructuredOriginalData] = useState([]);
	const [sundryFormResetValue, setSundryFormResetValue] = useState(false);
	const [disableMode, setDisableMode] = useState(false);
	const [configuredItem, setConfiguredItem] = useState(0);
	const [showUpdateButton, setShowUpdateButton] = useState(false);
	const [initialSundries, setInitialSundries] = useState([]);
	const [approvalStatus, setApprovalStatus] = useState(true);
	const [originalResponse, setOriginalResponse] = useState({});
	const [showSave, setShowSave] = useState(false);
	const [isTechnicalConfigurationChanged, setIsTechnicalConfigurationChanged] = useState(false);
	const [isSyncModalVisible, setIsSyncModalVisible] = useState(false);
	const [syncModalData, setSyncModalData] = useState({});

	const navigate = useNavigate();

	const updateSaveButton = () => {
		const data = [...partsData, ...staticRowsData];
		const saveData = jsonFormat(data);

		const allParts = saveData.kmsDetails.flatMap(
			(kmDetail) => kmDetail.parts || []
		);

		const hasEditableParts = allParts.some(
			(part) => part.isChargesApplicable === true
		);

		setShowSave(!hasEditableParts);
	};

	useEffect(() => {
		updateSaveButton();
	}, [partsData]);

	const onCheckEditOrNew = (data) => {
		const allParts = data.kmsDetails.flatMap(
			(kmDetail) => kmDetail.parts || []
		);
		const hasEditableParts = allParts.some(
			(part) => part.isChargesApplicable === true
		);
		if (hasEditableParts) {
			setDisableMode(true);
			setConfiguredItem(data.id);
			const allSundries = data.kmsDetails.flatMap(
				(kmDetail) => kmDetail.sundries || []
			);
			setInitialSundries(allSundries);
		} else {
			setDisableMode(false);
		}
	};

	function sortByCodeAsc(array) {
		return array.slice().sort((a, b) => {
			const codeA = a.code.toUpperCase();
			const codeB = b.code.toUpperCase();
			if (codeA < codeB) return -1;
			if (codeA > codeB) return 1;
			return 0;
		});
	}

	const getGspConfigurationsParts = async () => {
		try {
			setIsLoading(true);
			const responseData = await apiService.get(
				`${getGspConfigurations}${selectedModel.id}`
			);
			if (responseData.data) {
				await bindGSPconfigurationData(responseData);
			} else {
				setNoTechConfig(true);
				setIsLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error.message);
		}
	};

	const bindGSPconfigurationData = async (responseData) =>{
		if (typeof responseData.data === "string") {
			if (responseData.data.includes("technical")) {
				setNoTechConfig(true);
			} else {
				setTotalIntervals(0);
			}
		} else if (
			responseData.data.kmsDetails &&
			Array.isArray(responseData.data.kmsDetails)
		) {
			setTotalIntervals(responseData.data.kmsDetails.length);
		}
		setFetchedPartsData(responseData.data);
		setOriginalResponse(responseData.data);
		onCheckEditOrNew(responseData.data);
		setToggleExpand(false);
		const structuredData = transformData(responseData);
		const initialRows = [
			...structuredData.parts,
			...structuredData.staticRows,
		];
		setApprovalStatus(responseData.data?.isApproved);
		setKmTitleData(structuredData.kmDetailsGsp);
		setStructuredOriginalData(initialRows);
		setPartsData(structuredData.parts);
		setStaticRowsData(structuredData.staticRows);
		setIsTechnicalConfigurationChanged(responseData.data?.isTechnicalConfigurationChanged || false);
		setIsLoading(false);
	}

	useEffect(() => {
		if (selectedModel && selectedModel.id) {
			getGspConfigurationsParts();
			setSundryFormResetValue(false);
		}
	}, [selectedModel]);

	const updateKmTitleData = useCallback(() => {
		return kmTitleData.map((kmDetail) => {
			const kmId = kmDetail.kmId;
			const allActive = partsData.every((part) =>
				part.kmsDetails.some(
					(detail) => detail.kmId === kmId && detail.isActive
				)
			);

			return {
				...kmDetail,
				isTitleChekBoxActive: allActive,
			};
		});
	}, [kmTitleData, partsData]);

	const updatePartsData = useCallback(() => {
		return partsData.map((part) => {
			const allIntervalsActive = part.kmsDetails.every(
				(detail) => detail.isActive
			);
			return {
				...part,
				isPartSelected: allIntervalsActive,
			};
		});
	}, [partsData]);

	const updateRowSpan = useCallback(() => {
		setStaticRowsData((prevStaticRowsData) => {
			const count = prevStaticRowsData.filter((row) => row.code).length;
			setSundryRowSpanValue(count);
			return prevStaticRowsData;
		});
	}, []);

	const calcualtionStaticRows = useCallback(() => {
		const calculatedPartsAndLubs = calculatePartsAndLubricants(
			partsData,
			staticRowsData,
			kmTitleData
		);
		const subtotalRowscalculated = calculateSubtotalRows(
			calculatedPartsAndLubs
		);
		const taxRowsCalculated = calculateTax(subtotalRowscalculated);
		const calculatedGrandTotal = grandTotalCalulate(taxRowsCalculated);
		const calculatedExcGST = calculateExcGST(calculatedGrandTotal);
		const calcualtedRebate = calculateRebate(calculatedExcGST);
		const calculated3YData = calculate3YProgramme(calcualtedRebate);
		const calculated4YData = calculate4YProgramme(calculated3YData);
		const updatedRows = calculate5YProgramme(calculated4YData);
		setStaticRowsData(updatedRows);
	}, [partsData]);

	useEffect(() => {
		updateRowSpan();
	}, [staticRowsData, updateRowSpan]);

	useEffect(() => {
		if (partsData.length !== 0) {
			if (partsData.length === 0 || kmTitleData.length === 0) {
				setIsAllCheckBoxChecked(false);
				return;
			}
			let needToUpdate = false;
			const updatedKmTitleData = updateKmTitleData();
			if (
				!kmTitleData.every(
					(item, index) =>
						item.isTitleChekBoxActive ===
						updatedKmTitleData[index].isTitleChekBoxActive
				)
			) {
				needToUpdate = true;
			}

			const updatedPartsData = updatePartsData();
			if (
				!partsData.every(
					(item, index) =>
						item.isPartSelected === updatedPartsData[index].isPartSelected
				)
			) {
				needToUpdate = true;
			}
			if (needToUpdate) {
				setKmTitleData(updatedKmTitleData);
				setPartsData(updatedPartsData);
				const allPartsSelected = updatedPartsData.every(
					(part) => part.isPartSelected
				);
				const allKmTitlesActive = updatedKmTitleData.every(
					(kmDetail) => kmDetail.isTitleChekBoxActive
				);
				setIsAllCheckBoxChecked(allPartsSelected && allKmTitlesActive);
			}
			calcualtionStaticRows();
		}
	}, [
		partsData,
		kmTitleData,
		updateKmTitleData,
		updatePartsData,
		calcualtionStaticRows,
	]);

	const handleCheckboxChange = (e, record) => {
		const kmId = parseInt(e.target.id);
		const isChecked = e.target.checked;
		const newPartsData = partsData.map((eachItem) => {
			if ( eachItem.id === record.id  && eachItem.itemTechConfDetailId == record.itemTechConfDetailId ) {
				const updatedKmsDetails = eachItem.kmsDetails.map((interval) => {
					if (interval.kmId === kmId) {
						const normalPrice = interval.basePrice;
						return { ...interval, isActive: isChecked, price: normalPrice };
					}
					return interval;
				});
				return { ...eachItem, kmsDetails: updatedKmsDetails };
			}
			return eachItem;
		});
		setCanChangeModel(false);
		setPartsData(newPartsData);
	};

	const handleOnChangePrice = (e, record, kmId) => {
		let newPrice = e.target.value;
		newPrice = newPrice.slice(0, 10);
		const regex = /^\d*\.?\d{0,2}$/;
		if (regex.test(newPrice)) {
			const newPartsData = partsData.map((eachItem) => {
				if (eachItem.id === record.id) {
					const updatedKmsDetails = eachItem.kmsDetails.map((detail) => {
						if (detail.kmId === kmId) {
							return { ...detail, price: newPrice };
						}
						return detail;
					});
					return { ...eachItem, kmsDetails: updatedKmsDetails };
				}
				return eachItem;
			});
			setCanChangeModel(false);
			setPartsData(newPartsData);
		}
	};

	const handleTitleCheckboxChange = (e, kmId) => {
		const newkmTitleData = kmTitleData.map((eachItem) => {
			if (eachItem.kmId === kmId) {
				return { ...eachItem, isTitleChekBoxActive: e.target.checked };
			}
			return eachItem;
		});
		setKmTitleData(newkmTitleData);
		const newPartsData = partsData.map((eachItem) => {
			if (eachItem.partNumber) {
				const updatedKmsDetails = eachItem?.kmsDetails?.map((interval) => {
					if (interval?.kmId === kmId) {
						const normalPrice = interval.basePrice;
						return {
							...interval,
							isActive: e.target.checked,
							price: normalPrice,
						};
					}
					return interval;
				});
				return { ...eachItem, kmsDetails: updatedKmsDetails };
			}
			return eachItem;
		});
		setCanChangeModel(false);
		setPartsData(newPartsData);
	};

	const handlePartCheckboxChange = (e, record) => {
		const isChecked = e.target.checked;

		const newPartsData = partsData.map((eachItem) => {
			if ( eachItem.id === record.id  && eachItem.itemTechConfDetailId === record.itemTechConfDetailId ) {
				const updatedKmsDetails = eachItem.kmsDetails.map((interval) => {
					const normalPrice = interval.basePrice;
					return { ...interval, isActive: isChecked, price: normalPrice };
				});
				return {
					...eachItem,
					isPartSelected: isChecked,
					kmsDetails: updatedKmsDetails,
				};
			}
			return eachItem;
		});
		setCanChangeModel(false);
		setPartsData(newPartsData);
	};

	const handleMainCheckboxChange = (e) => {
		const isChecked = e.target.checked;

		const newPartsData = partsData.map((eachItem) => {
			const updatedKmsDetails = eachItem.kmsDetails.map((interval) => ({
				...interval,
				isActive: isChecked,
				price: interval.basePrice,
			}));

			return {
				...eachItem,
				isPartSelected: isChecked,
				kmsDetails: updatedKmsDetails,
			};
		});

		const newKmTitleData = kmTitleData.map((eachItem) => ({
			...eachItem,
			isTitleChekBoxActive: isChecked,
		}));
		setCanChangeModel(false);
		setPartsData(newPartsData);
		setKmTitleData(newKmTitleData);
		setIsAllCheckBoxChecked(isChecked);
	};

	const handleOnChangeIncGST = (e, record, kmId) => {
		let value = e.target.value;
		value = value.slice(0, 10);
		const regex = /^\d*\.?\d{0,2}$/;
		if (regex.test(value)) {
			const newStaticRows = staticRowsData.map((eachItem) => {
				if (eachItem.id === record.id) {
					const updatedKmsDetails = eachItem.kmsDetails.map((detail) => {
						if (detail.kmId === kmId) {
							return { ...detail, price: value };
						}
						return detail;
					});
					return { ...eachItem, kmsDetails: updatedKmsDetails };
				}
				return eachItem;
			});
			const calculatedExcGST = calculateExcGST(newStaticRows);
			const calcualtedRebate = calculateRebate(calculatedExcGST);
			const calculated3YData = calculate3YProgramme(calcualtedRebate);
			const calculated4YData = calculate4YProgramme(calculated3YData);
			const updatedRows = calculate5YProgramme(calculated4YData);
			setStaticRowsData(updatedRows);
			setCanChangeModel(false);
		}
	};

	const handleOnBlurIncGST = (e, record, kmId) => {
		const value = e.target.value.trim();
		const correctedValue = value === "" ? "0" : value;
		const newStaticRows = staticRowsData.map((eachItem) => {
			if (eachItem.id === record.id) {
				const updatedKmsDetails = eachItem.kmsDetails.map((detail) => {
					if (detail.kmId === kmId) {
						return { ...detail, price: correctedValue };
					}
					return detail;
				});
				return { ...eachItem, kmsDetails: updatedKmsDetails };
			}
			return eachItem;
		});
		setStaticRowsData(newStaticRows);
	};

	const handleOnBlurInput = (record, kmId) => {
		const newPartsData = partsData.map((eachItem) => {
			if (eachItem.id === record.id) {
				const updatedKmsDetails = eachItem.kmsDetails.map((detail) => {
					if (detail.kmId === kmId) {
						if (detail.price === "" && detail.isActive === true)
							return { ...detail, isActive: false };
					}
					return detail;
				});
				return { ...eachItem, kmsDetails: updatedKmsDetails };
			}
			return eachItem;
		});

		setPartsData(newPartsData);
	};

	const recommendedSerivcesColumns = kmTitleData?.map((item) => ({
		title: () => (
			<div className='check-box-value-div'>
				<Checkbox
					disabled={disableMode}
					onChange={(e) => handleTitleCheckboxChange(e, item.kmId)}
					checked={item.isTitleChekBoxActive}
				/>
				<span>{`${item?.code}`}</span>
			</div>
		),
		className: "generative-cells",
		width: 80,
		key: `${item.kmId}`,
		render: (_, record) => {
			if (record.partNumber) {
				const kmDetail = record.kmsDetails?.find(
					(detail) => detail.kmId === item.kmId
				);
				return (
					kmDetail &&
					(kmDetail?.isActive && !disableMode ? (
						<div className='check-box-value-div'>
							<Checkbox
								disabled={disableMode}
								id={kmDetail?.kmId}
								onChange={(e) => {
									handleCheckboxChange(e, record);
								}}
								checked={kmDetail.isActive}
							/>
							<Form.Item className='form-input-cell'>
								<Input
									disabled={disableMode}
									className='cell-input'
									value={kmDetail?.price}
									onChange={(e) =>
										handleOnChangePrice(e, record, kmDetail.kmId)
									}
									onBlur={() => handleOnBlurInput(record, kmDetail.kmId)}
								/>
							</Form.Item>
						</div>
					) : (
						<div className='check-box-value-div'>
							<Checkbox
								disabled={disableMode}
								id={kmDetail?.kmId}
								checked={kmDetail.isActive}
								onChange={(e) => {
									handleCheckboxChange(e, record);
								}}
							/>
							<span className='cell-text'>
								${kmDetail.isActive ? kmDetail?.price : 0}
							</span>
						</div>
					))
				);
			} else if (record.totalIncGST) {
				const kmDetail = record.kmsDetails?.find(
					(detail) => detail.kmId === item.kmId
				);
				return (
					<div className='gst-input-div'>
						{!disableMode ? (
							<Input
								className='cell-input'
								disabled={disableMode}
								value={kmDetail?.price}
								onChange={(e) => handleOnChangeIncGST(e, record, kmDetail.kmId)}
								onBlur={(e) => handleOnBlurIncGST(e, record, kmDetail.kmId)}
							/>
						) : (
							<span className='cell-text'>
								$
								{kmDetail?.price.toLocaleString("en-US", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
							</span>
						)}
					</div>
				);
			} else if (record.smp3 || record.smp4 || record.smp5) {
				const kmDetail = record.kmsDetails?.find(
					(detail) => detail.kmId === item.kmId
				);
				return (
					<div className='nomal-cell-text-cont'>
						<span className='cell-text'>
							{kmDetail?.price === 0
								? "-"
								: `$${kmDetail?.price?.toLocaleString("en-US", {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
								  })}`}
						</span>
					</div>
				);
			} else {
				const kmDetail = record.kmsDetails?.find(
					(detail) => detail.kmId === item.kmId
				);
				return (
					<div className='nomal-cell-text-cont'>
						<span
							className={`${
								record.grandTotal || record.subTotal || record.partsLubricants
									? "grand-total-price-text"
									: "cell-text"
							}`}>
							{`${
								kmDetail?.price !== "-" ? "$" : ""
							}${kmDetail?.price?.toLocaleString("en-US", {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}`}
						</span>
					</div>
				);
			}
		},
	}));

	const columns = [
		{
			title: (
				<div className='parts-checkbox-div'>
					<Checkbox
						className='check-box-style'
						disabled={disableMode}
						checked={isAllCheckBoxChecked}
						onChange={(e) => {
							handleMainCheckboxChange(e);
						}}
					/>
				</div>
			),
			width: 16,
			dataIndex: "partName",
			className: "check-box-column-style",
			ellipsis: true,
			fixed: "left",
			render: (_, record, index) => {
				if (record.partNumber) {
					return {
						children: (
							<div className='parts-checkbox-div'>
								<Checkbox
									disabled={disableMode}
									checked={record.isPartSelected}
									onChange={(e) => {
										handlePartCheckboxChange(e, record);
									}}
								/>
							</div>
						),
						props: {
							colSpan: 1,
						},
					};
				} else if (record.partsLubricants) {
					return {
						children: (
							<div className='merged-row-title'>Parts and Lubricants</div>
						),
						props: {
							colSpan: 4,
						},
					};
				} else if (record.labour) {
					return {
						children: <div className='labour-row-style'>Labour</div>,
						props: {
							colSpan: 3,
						},
					};
				} else if (record.code && record.rowSpan === 1) {
					return {
						children: <div className='labour-row-style'>Sundries</div>,
						props: {
							colSpan: 2,
							rowSpan: sundryRowSpanValue,
						},
					};
				} else if (record.code && record.rowSpan > 1) {
					return {
						children: <div className='labour-row-style'>Sundries</div>,
						props: {
							colSpan: 2,
							rowSpan: 0,
						},
					};
				} else if (record.subTotal) {
					return {
						children: <div className='labour-row-style'>Subtotal</div>,
						props: {
							colSpan: 3,
						},
					};
				} else if (record.tax) {
					return {
						children: <div className='labour-row-style'>Tax</div>,
						props: {
							colSpan: 3,
						},
					};
				} else if (record.grandTotal) {
					return {
						children: (
							<div className='parts-checkbox-div'>
								<Button
									type='primary'
									style={{ backgroundColor: "transperent" }}
									className='expandable-icon-div'
									icon={
										toggleExpand ? (
											<MinusOutlined className='expand-icon' />
										) : (
											<PlusOutlined className='expand-icon' />
										)
									}
									onClick={toggleRowExpansion}
								/>
							</div>
						),
						props: {
							colSpan: 1,
						},
					};
				} else if (record.totalIncGST) {
					return {
						children: (
							<div className='labour-row-style'>Customer Transaction</div>
						),
						props: {
							colSpan: 2,
							rowSpan: 2,
						},
					};
				} else if (record.totalExcGST) {
					return {
						children: (
							<div className='labour-row-style'>Customer Transaction</div>
						),
						props: {
							colSpan: 0,
						},
					};
				} else if (record.rebate) {
					return {
						children: <div className='labour-row-style'>Rebate</div>,
						props: {
							colSpan: 2,
							rowSpan: 1,
						},
					};
				} else if (record.rsa) {
					return {
						children: <div className='labour-row-style'>RSA</div>,
						props: {
							colSpan: 2,
						},
					};
				} else if (record.smp3) {
					return {
						children: (
							<span className='break-words'>Service Marketing Programmes</span>
						),
						props: {
							colSpan: 2,
							rowSpan: 3,
						},
					};
				} else if (record.smp4 || record.smp5) {
					return {
						props: {
							colSpan: 0,
						},
					};
				}
				return {
					props: {
						colSpan: 2,
					},
				};
			},
		},
		{
			title: "Part Number",
			width: 80,
			dataIndex: "partNumber",
			className: "part-number-style",
			fixed: "left",
			ellipsis: true,
			render: (_, record, index) => {
				if (record.partNumber) {
					return {
						children: <span className='record-text'>{record.partNumber}</span>,
						props: {
							colSpan: 1,
						},
					};
				} else if (record.code) {
					return {
						props: {
							colSpan: 0,
						},
					};
				} else if (record.grandTotal) {
					return {
						children: (
							<div className='grand-total-style'>{record.grandTotal}</div>
						),
						props: {
							colSpan: 2,
						},
					};
				} else {
					return {
						props: {
							colSpan: 0,
						},
					};
				}
			},
		},
		{
			title: "Description",
			width: 80,
			dataIndex: "name",
			fixed: "left",
			className: "description-column-style",
			sorter: commonSorter("name"),
			ellipsis: true,
			render: (_, record, index) => {
				if (
					record.partsLubricants ||
					record.labour ||
					record.subTotal ||
					record.tax ||
					record.grandTotal
				) {
					return {
						props: {
							colSpan: 0,
						},
					};
				} else if (record.code) {
					return {
						children: <div className='record-text'>{record.code}</div>,
						props: {
							colSpan: 1,
						},
					};
				} else if (record.totalIncGST) {
					return {
						children: <div className='record-text'>Customer Payment</div>,
						props: {
							colSpan: 1,
							rowSpan: 2,
						},
					};
				} else if (record.totalExcGST) {
					return {
						props: {
							colSpan: 0,
						},
					};
				} else if (record.smp3) {
					return {
						children: <div className='labour-row-style'>3Y/45K Programme</div>,
						props: {
							colSpan: 2,
							rowSpan: 1,
						},
					};
				} else if (record.smp4) {
					return {
						children: <div className='labour-row-style'>4Y/60K Programme</div>,
						props: {
							colSpan: 2,
							rowSpan: 1,
						},
					};
				} else if (record.smp5) {
					return {
						children: <div className='labour-row-style'>5Y/75K Programme</div>,
						props: {
							colSpan: 2,
							rowSpan: 1,
						},
					};
				} else if (record.rebate) {
					return {
						children: <div className='record-text'>Claim amount</div>,
						props: {
							colSpan: 1,
							rowSpan: 1,
						},
					};
				} else if (record.rsa) {
					return {
						children: <div className='record-text'>Dealer Payment</div>,
						props: {
							colSpan: 1,
							rowSpan: 1,
						},
					};
				}
				return <span className='record-text'>{record.name}</span>;
			},
		},
		{
			title: "Quantity",
			width: 60,
			dataIndex: "qty",
			fixed: "left",
			ellipsis: true,
			className: "quantity-column-style",
			sorter: (a, b) => a.qty - b.qty,
			render: (_, record, index) => {
				if (record.partsLubricants) {
					return {
						props: {
							colSpan: 0,
						},
					};
				} else if (
					record.labour ||
					record.subTotal ||
					record.tax ||
					record.grandTotal ||
					record.rsa ||
					record.code
				) {
					return {
						children: <div className='merged-row-title'>-</div>,
						props: {
							colSpan: 1,
						},
					};
				} else if (record.qty) {
					return {
						children: (
							<div className='nomal-cell-text-cont'>
								<span className='cell-text'>
									{parseFloat(record.qty).toFixed(2)}
								</span>
							</div>
						),
						props: {
							colSpan: 1,
						},
					};
				} else if (record.totalIncGST) {
					return {
						children: <div className='gst-text'>Including GST</div>,
						props: {
							colSpan: 1,
						},
					};
				} else if (record.totalExcGST) {
					return {
						children: <div className='gst-text'>Excluding GST</div>,
						props: {
							colSpan: 1,
						},
					};
				} else if (record.rebate) {
					return {
						children: <div className='gst-text'>Excluding GST</div>,
						props: {
							colSpan: 1,
						},
					};
				} else if (record.smp3 || record.smp4 || record.smp5) {
					return {
						props: {
							colSpan: 0,
						},
					};
				}

				return <span className='record-text'>{record.qty}</span>;
			},
		},
		...recommendedSerivcesColumns,
		sundryRowSpanValue > 0 &&
			!disableMode && {
				title: "Action",
				fixed: "right",
				width: totalIntervals > 2 ? 40 : 30,
				className: "gsp-table-action-column",
				render: (_, record) => (
					<Tooltip title='Remove sundry'>
						<div className='cross-btn-container'>
							{record.code && (
								<ConfirmDelete
									icon={
										<Button className='action-btn-gsp'>
											<CloseOutlined className='Sundry-close-button' />
										</Button>
									}
									text={`Are you sure you want to remove the ${record?.code}?`}
									label={"Cancel"}
									onConfirm={() => deleteSundryItem(record)}
								/>
							)}
						</div>
					</Tooltip>
				),
			},
	].filter(Boolean);

	const onAddSundry = (data, form) => {
		setCanChangeModel(false);
		setIsLoading(true);
		const parsedValue = parseFloat(data.value);
		const formattedValue = parsedValue.toFixed(2);
		const spanValue = sundryRowSpanValue;
		const newKmsDetail = kmTitleData.map((eachkm) => ({
			kmId: eachkm.kmId,
			price: parseFloat(formattedValue),
		}));
		const newRow = {
			...data,
			key: data.code,
			kmsDetails: newKmsDetail,
			expanded: toggleExpand,
			rowSpan: spanValue + 1,
		};

		const existingIndex = staticRowsData.findIndex(
			(row) => row.id === newRow.id
		);

		if (existingIndex !== -1) {
			form.setFields([
				{
					name: "sundry",
					errors: ["Sundry already exists!"],
				},
			]);
			setIsLoading(false);
			return;
		}

		let insertIndex;
		const labourIndex = staticRowsData.findIndex((row) => row.labour);
		const subtotalIndex = staticRowsData.findIndex((row) => row.subtotal);

		if (labourIndex !== -1 && subtotalIndex !== -1) {
			insertIndex =
				subtotalIndex > labourIndex ? labourIndex + 1 : subtotalIndex;
		} else if (labourIndex !== -1) {
			insertIndex = labourIndex + 1;
		} else {
			insertIndex = 0;
		}
		while (
			insertIndex < staticRowsData.length &&
			staticRowsData[insertIndex].rowSpan < newRow.rowSpan
		) {
			insertIndex++;
		}
		const updatedStaticRowsData = [
			...staticRowsData.slice(0, insertIndex),
			newRow,
			...staticRowsData.slice(insertIndex),
		];
		const subtotalRowscalculated = calculateSubtotalRows(updatedStaticRowsData);
		const taxRowsCalculated = calculateTax(subtotalRowscalculated);
		const calculatedGrandTotal = grandTotalCalulate(taxRowsCalculated);
		const calculatedExcGST = calculateExcGST(calculatedGrandTotal);
		const calcualtedRebate = calculateRebate(calculatedExcGST);
		const calculated3YData = calculate3YProgramme(calcualtedRebate);
		const calculated4YData = calculate4YProgramme(calculated3YData);
		const updatedRows = calculate5YProgramme(calculated4YData);
		setStaticRowsData(updatedRows);
		setIsLoading(false);
		form.resetFields();
	};

	const verifyModelChange = ({ onSelectModel, option }) => {
		if (canChangeModel) {
			onSelectModel(option);
			setCanChangeModel(true);
			setSundryFormResetValue(true);
			setShowUpdateButton(false);
		} else {
			const contentMsg = "Are you sure you want to discard the changes?";
			const icon = <ExclamationCircleOutlined />;
			const label = "Discard";
			confirmPopup({
				label,
				icon,
				contentMsg,
				onOKFunction: () => {
					onSelectModel(option);
					setCanChangeModel(true);
					setSundryFormResetValue(true);
					setShowUpdateButton(false);
				},
			});
		}
	};

	const handleModelSelect = (model) => {
		setNoTechConfig(false);
		setToggleExpand(false);
		setIsAllCheckBoxChecked(false);
		setSelectedModel(model);
		setIsModelSelected(true);
	};

	const handleSave = async () => {
		const data = [...partsData, ...staticRowsData];
		const saveData = jsonFormat(data);
		const currentSundries = saveData.kmsDetails[0].sundries;
		const finalSundries = [...currentSundries, ...initialSundries];
		const finalKmdetails = saveData.kmsDetails.map((eachItem) => {
			return { ...eachItem, sundries: finalSundries };
		});

		const postData = {
			id: configuredItem,
			isApproved: approvalStatus,
			modelId: fetchedPartsdata?.modelId,
			kmsDetails: finalKmdetails,
		};
		try {
			const response = await apiService.post(gspConfiguration, postData);
			message.success(response.message);
			getGspConfigurationsParts();
			setCanChangeModel(true);
			setIsAllCheckBoxChecked(false);
			setToggleExpand(false);
		} catch (error) {
			message.error(error.message);
		}
	};

	const toggleRowExpansion = () => {
		const togglekey = !toggleExpand;
		const updatedRowsData = staticRowsData.map((row) =>
			row.expanded !== undefined ? { ...row, expanded: togglekey } : row
		);
		setStaticRowsData(updatedRowsData);
		setToggleExpand(togglekey);
	};

	const deleteSundryItem = (record) => {
		setIsLoading(true);
		setSundryRowSpanValue(sundryRowSpanValue - 1);
		const updatedRows = staticRowsData.filter(
			(eachRow) => eachRow.id !== record?.id
		);

		let count = 0;
		const newRows = updatedRows.map((row) => {
			if (row.code) {
				count += 1;
				return { ...row, rowSpan: count };
			}
			return row;
		});
		setStaticRowsData(newRows);
		setInitialSundries([...initialSundries, { ...record, deleted: true }]);
		setIsLoading(false);
	};

	const onHandleApproval = async (e) => {
		setApprovalStatus(e);
		const postData = { ...originalResponse, isApproved: e };
		try {
			const response = await apiService.post(gspConfiguration, postData);
			if (response.message && response.message.includes("success")) {
				message.success("GSP Configuration marked as approved!");
			}
			getGspConfigurationsParts();
			setCanChangeModel(true);
		} catch (error) {
			message.error(error.message);
		}
	};

	const confirmApproval = (e) => {
		Modal.confirm({
			title: `Confirm ${approvalStatus ? "Unapproval" : "Approval"}`,
			className: "delete-popup",
			icon: <ExclamationCircleOutlined />,
			content: approvalStatus
				? "Do you wish to unconfirm the GSP approval for this model?"
				: "Do you wish to approve GSP for this model?",
			okText: "Yes",
			cancelText: "Cancel",
			onOk() {
				onHandleApproval(e);
			},
		});
	};

	const onCancelGSPConfig = () => {
		setIsAllCheckBoxChecked(false);
		setToggleExpand(false);
		getGspConfigurationsParts();
	};

	const syncTechConfiguration = async () => {
		try {
			setIsLoading(true);
			const responseData = await apiService.get(`${syncTechnicalConfiguration}/${selectedModel.id}`);
			if(responseData.data.isTechnicalConfigurationChanged){
				if (responseData.data) {
					// delete responseData.data.kmsDetailsSync;
							setSyncModalData(responseData);
							setIsSyncModalVisible(true)
					 
				} else {
					setNoTechConfig(true);
				}
			}
			setIsLoading(false);

		} catch (error) {
			console.error("Error fetching data:", error.message);

		}
	}

	const handleModalConfirm = async() => {
		setIsSyncModalVisible(false)
		 delete syncModalData.data.kmsDetailsSync;
		await bindGSPconfigurationData(syncModalData);
	};

	return (
		<>
			<div className='gsp-config-main-bg'>
				<ModelDetailsHeader
					onModelSelect={handleModelSelect}
					verifyModelChange={verifyModelChange}
				/>
				{totalIntervals === 0 ? (
					<RedirectModelServiceDiv model={selectedModel} />
				) : noTechConfig ? (
					<RedirectTechDiv model={selectedModel} />
				) : (
					<div>
						<SundryHeader
							onAddSundryItem={onAddSundry}
							isModelSelected={isModelSelected}
							resetValue={sundryFormResetValue}
							editValue={disableMode}
						/>
						<div>
							{!isModelSelected ? (
								<EmptyModelTableContent text='Select model to configure or show GSP' />
							) : (
								<div>
									<h1 className='title-style'>Part Details</h1>
									<div className='gsp-main-table-bg'>
										<Table
											columns={columns}
											dataSource={[...partsData, ...staticRowsData]}
											scroll={{
												x:
													totalIntervals <= 3
														? 1000
														: totalIntervals <= 5
														? 1200
														: totalIntervals <= 8
														? 1600
														: totalIntervals * 190,
											}}
											pagination={false}
											loading={isLoading}
											bordered={true}
											rowClassName={(record) =>
												record.grandTotal
													? "grand-total-row"
													: (record.tax ||
															record.labour ||
															record.partsLubricants) &&
													  record.expanded === false
													? "hidden-row"
													: record.code
													? `sundry-row ${
															record.expanded === false ? "hidden-row" : ""
													  }`
													: record.subTotal
													? `sub-total ${
															record.expanded === false ? "hidden-row" : ""
													  }`
													: record.labour
													? "labour-price-style"
													: record.totalIncGST
													? "customer-transaction"
													: record.partNumber
													? "part-number"
													: record.totalExcGST
													? "rowSpancustomerTransaction-style"
													: record.rebate
													? "rebate-style"
													: record.rsa
													? "rsa"
													: record.smp3
													? "smp3"
													: record.smp5
													? "smp5"
													: ""
											}
										/>
									</div>
											{isSyncModalVisible && (
												<SyncModal
												visible={isSyncModalVisible}
												data={syncModalData.data.kmsDetailsSync}
												onConfirm={handleModalConfirm}
												onCancel={() => setIsSyncModalVisible(false)} 
												/>
											)}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
			{isModelSelected && totalIntervals > 0 && !noTechConfig && (
				<div className='save-footer-bg'>
					<div className='save-cancel-header'>
						<div className=''>
							<span className='total-applied-intervals'>
								Total applied Intervals :
							</span>
							<span className='no-of-intrervals'>{` ${totalIntervals} Intervals`}</span>
						</div>
						<div className='buttons-cont'>
							{!disableMode ? (
								<ConfirmDelete
									icon={
										<Button
											style={{ borderRadius: "2px" }}
											className='cancel-button'>
											Cancel
										</Button>
									}
									text={`Are you sure you want to cancel all the changes?`}
									label={"Cancel"}
									onConfirm={onCancelGSPConfig}
								/>
							) : (
								<div className='approve-mark-div'>
									<h1 className='approve-mark-style'>Mark as Approved</h1>
									<Switch
										className='check-box-style'
										checkedChildren='YES'
										unCheckedChildren='NO'
										checked={approvalStatus}
										onChange={confirmApproval}
									/>
								</div>
							)}
							{!disableMode ? (
								<Button
									className='save-button'
									type='primary'
									onClick={handleSave}
									disabled={showSave}>
									{`${showUpdateButton ? "Update GSP" : "Save GSP"}`}
								</Button>
							) : (
								<Button
									className='save-button'
									type='primary'
									onClick={() => {
										setDisableMode(false);
										setShowUpdateButton(true);
										setCanChangeModel(false);
									}}>
									Edit GSP Configuration
								</Button>
							)}
							<div hidden = {!isTechnicalConfigurationChanged}>
								<Button
									className='save-button'
									type='primary'
									onClick={() => {
										syncTechConfiguration()
									}}
								>
								Sync Configuration
								</Button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default GspConfiguration;
