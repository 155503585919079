import React, { useState, useEffect } from "react";
import "./pageHeader.css";
import { Breadcrumb, Layout } from "antd";
import { useLocation, Link } from "react-router-dom";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import apiService from "../../Services/commonServices";
import { profile } from "../../Services/Endpoints";

const { Header } = Layout;

const PageHeader = () => {
	const location = useLocation();
	const [userDetails, setUserDetails] = useState([]);
	const currentPath = location.pathname;
	const pathSegments = currentPath
		.split("/")
		.filter((segment) => segment !== "");
	const lastSegment = pathSegments[0];
	const isHomeRoute = currentPath === "/";
	function capitalizeString(lastSegment) {
		if (lastSegment === "km-month") {
			return "KM/Month";
		} else if (lastSegment === "gsp-configuration") {
			return "GSP Configuration";
		} else if (lastSegment === "gsp-overview") {
			return "GSP Overview";
		} else if (lastSegment === "category") {
			return "Category Master";
		} else {
			const words = lastSegment.split("-");
			const capitalizedWords = words.map((word) => {
				const capitalizedFirstLetter = word.charAt(0).toUpperCase();
				const restOfWord = word.slice(1);
				return capitalizedFirstLetter + restOfWord;
			});
			const capitalizedString = capitalizedWords.join(" ");
			return capitalizedString;
		}
	}

	useEffect(() => {
		userData();
	}, []);

	const userData = async () => {
		try {
			const userDetails = await apiService.get(profile);
			setUserDetails(userDetails.userDetails);
		} catch (error) {
			console.error("Error fetching data:", error.message);
		}
	};

	return (
		<div className='page-header-container'>
			{!isHomeRoute && (
				<Header style={{ gap: "0px" }}>
					<Breadcrumb style={{ margin: "16px 0" }}>
						<Breadcrumb.Item>
							<Link to='/' className='breadcrumb-item-home'>
								Home
							</Link>
						</Breadcrumb.Item>
						<BreadcrumbItem className='breadcrumb-item-1'>
							{capitalizeString(lastSegment)}
						</BreadcrumbItem>
					</Breadcrumb>
					<h2 className='bread-crumb-title-custom'>
						{capitalizeString(lastSegment)}
					</h2>
				</Header>
			)}
			{isHomeRoute && (
				<Header style={{ flexGrow: "1" }}>
					<h1 className='welcome-user-name'>
						Hi, {`${userDetails.firstName || ""} ${userDetails.lastName || ""}`}
					</h1>
					<span className='welcome-msg'>
						Welcome to Genuine Service Program
					</span>
				</Header>
			)}
		</div>
	);
};

export default PageHeader;
