import React from "react";
import { Input} from "antd";
import "./customSearch.css";
const { Search } = Input;

const CustomSearch = ({ onChange, onSearch, customSearchValue}) => (
  <div className="custom-search-parent-container">
    <Search
      placeholder="Search"
      allowClear
      onSearch={onSearch}
      onChange={onChange}
      value ={customSearchValue}
      style={{
        maxWidth: 250,
      }}
    />
  </div>
);
export default CustomSearch;
