import { atom } from 'recoil';

export const sideNavCollapsedState = atom({
  key: 'sideNavCollapsedState',
  default: true,
});

export const isAuthenticatedStatus = atom({
  key: 'isAuthenticated', 
  default: true,
});

