export const getMaxCharsRule = (fieldName, maxChars = 50) => ({
	max: maxChars,
	message: `Max ${maxChars} characters.`,
});

export const getAlphabeticRule = (fieldName) => ({
	pattern: /^[A-Za-z\s]+$/,
	message: `Only alphabetic characters are allowed for ${fieldName}.`,
});

export const requiredRule = {
	required: true,
	message: "This field is required.",
};

export const zeroValueRule = {
	validator: (_, value) => {
		if (value === "0.00") {
			return Promise.reject(new Error("Value must be greater than zero."));
		}
		return Promise.resolve();
	},
};

export const alphanumericSpecialRule = {
	pattern: /^[A-Za-z0-9\s!@#$%^&*()-_+=|{}\[\]:;"'<>,.?/~]+$/,
	message: "Alphanumeric and special characters are allowed.",
};

export const onHandleMaxCharacterValidation = (
	e,
	form,
	fieldName,
	maxCharacters
) => {
	const inputValue = e.target.value;
	const inputLength = inputValue.length;

	// Truncate input value if it exceeds the maximum character limit
	if (inputLength > maxCharacters) {
		const truncatedValue = inputValue.slice(0, maxCharacters);
		form.setFieldsValue({
			[fieldName]: truncatedValue,
		});

		form.setFields([
			{
				name: fieldName,
				errors: ["Max 200 characters."],
			},
		]);
	} else {
		form.setFields([
			{
				name: fieldName,
				errors: [],
			},
		]);
	}
};

export const onHandleAlphaNumericValidation = (e, form, fieldName) => {
	const key = e.key;
	const regex = /^[^A-Za-z0-9\s]+$/;
	if (regex.test(key)) {
		e.preventDefault();
		form.setFields([
			{
				name: fieldName,
				errors: ["Alphanumeric only."],
			},
		]);
	}
};

export const onHandleNumericValidation = (e, form, fieldName) => {
	const { value } = e.target;
	const key = e.key;
	const regex = /^[0-9.]+$/;

	// Prevent non-numeric input
	if (!regex.test(key) && key !== "Backspace") {
		e.preventDefault();
		form.setFields([
			{
				name: fieldName,
				errors: ["Numeric values only."],
			},
		]);
		return;
	}

	if (key === "." && value === "") {
		e.preventDefault();
		form.setFields([
			{
				name: fieldName,
				errors: ["Numeric values only."],
			},
		]);
		return;
	}

	// Allow only two decimal places
	if (key === "." && value.includes(".")) {
		e.preventDefault();
		return;
	}

	const decimalIndex = value.indexOf(".");
	if (decimalIndex !== -1 && value.length - decimalIndex > 2) {
		e.preventDefault();
		form.setFields([
			{
				name: fieldName,
				errors: ["Max 2 decimals allowed."],
			},
		]);
	}
};

export const onHandleAlphabaticValidation = (e, form, fieldName) => {
	const key = e.key;
	const regex = /^[A-Za-z]+$/;
	if (!regex.test(key)) {
		e.preventDefault();
		form.setFields([
			{
				name: fieldName,
				errors: ["Alphabetic characters only."],
			},
		]);
	}
};

export const requiredSelectRule = {
	required: true,
	message: "Please select an option.",
};

export const numericWithTwoDecimalRule = {
	pattern: /^\d+(\.\d{1,2})?$/,
	message: "Max 2 decimals allowed.",
};

export const HandleNumericValidation = (e, form, fieldName) => {
	const { value } = e.target;
	const regex = /^\d*$/; // This regex allows only digits
	if (!regex.test(value) || value.length > 2) {
		form.setFields([
			{
				name: fieldName,
				errors: [
					!regex.test(value) ? "Numeric values only." : "Max 2 characters.",
				],
			},
		]);
	} else {
		form.setFields([
			{
				name: fieldName,
				errors: [],
			},
		]);
	}
};


