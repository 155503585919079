import { Modal } from "antd";

const confirmPopup = ({ label, icon, content, onOKFunction }) => {
  Modal.confirm({
    title: `Confirm ${label}`,
    className: "delete-popup",
    icon: icon,
    content: "Are you sure you want to discard the changes?",
    okText: "Yes",
    cancelText: "Cancel",
    onOk() {
      onOKFunction();
    },
  });
};

export default confirmPopup;
